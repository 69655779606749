import { DatePicker } from "@mui/x-date-pickers";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import { format } from "date-fns";
import {
  TableColumn,
  TABLE_SORTING_TYPES,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { useUnmount } from "hooks/useUnmount";
import * as billingActions from "modules/billing/actions";
import * as notificationsActions from "modules/notifications/actions";
import {
  isOrganizationBillingDataLoadingSelector,
  organizationBillingDataSelector,
  tableOrganizationProjectsBillingDataSelector,
  tableOrganizationTotalBillingDataSelector
} from "modules/billing/selectors";
import { TableOrganizationProjectBillingData } from "modules/billing/types";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { formatDate } from "utils/formatDate";
import {
  DATE_FORMATS,
  DEFAULT_CURRENCY,
  PROJECT_STATUS_LABELS,
  ROUTES
} from "../../constants";
import * as s from "./styles";
import { DATE_TYPES, DIALOG_TYPES, PROJECT_STATUSES } from "./types";
import { startOfMonth, sub } from "date-fns";
import {
  FIELD_TYPES,
  FormDialogProps
} from "components/common/FormDialog/types";
import dayjs from "dayjs";
import { usePrevious } from "hooks/usePrevious";
import { FormDialog } from "components/common/FormDialog";
import { downloadFile } from "utils/downloadFile";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { API_PATH } from "../../axios";

const tableColumns: TableColumn<TableOrganizationProjectBillingData>[] = [
  { key: "name", label: "Project Name" },
  { key: "region", label: "Region" },
  { key: "status", label: "Status" }
];

const CURRENCY_SELECT_ID = "currency";

const title = "Billing";

export const Billing: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
  }>();
  const organization = useSelector(organizationSelector);
  const billingData = useSelector(organizationBillingDataSelector);
  const isBillingDataLoading = useSelector(
    isOrganizationBillingDataLoadingSelector
  );
  const tableBillingData = useSelector(
    tableOrganizationProjectsBillingDataSelector
  );
  const tableBillingDataTotal = useSelector(
    tableOrganizationTotalBillingDataSelector
  );
  const currentDate = new Date();
  const minDate = sub(currentDate, { days: 120 });
  const [startDate, setStartDate] = useState<string | null>(
    formatDate(startOfMonth(currentDate), DATE_FORMATS.ISO_DATE)
  );
  const [endDate, setEndDate] = useState<string | null>(
    formatDate(currentDate, DATE_FORMATS.ISO_DATE)
  );
  const [currency, setCurrency] = useState<string>(DEFAULT_CURRENCY);

  const currencies = useMemo(
    () => (billingData ? Object.keys(billingData.costs) : []),
    [billingData]
  );

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const previousSelectedItemId = usePrevious(selectedItemId);
  const currentItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const currentProjectRegion = billingData?.projects
    ?.find((project) => project.id === currentItemId)
    ?.region.toLowerCase();
  const currentProjectName = billingData?.projects?.find(
    (project) => project.id === currentItemId
  )?.name;

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.GENERATE_BILLING_REPORT, isOpened: false });

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleGenerateBillingReportButtonClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.GENERATE_BILLING_REPORT,
      isOpened: true
    });
  }, []);

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Billing",
      url: generatePath(ROUTES.BILLING, {
        organizationId: matchParams.organizationId
      })
    }
  ];

  const tableActions: TableRowActionsMenuItem<TableOrganizationProjectBillingData>[] =
    [
      {
        label: "Generate billing report",
        handler: handleGenerateBillingReportButtonClick
        // isDisabled: (project) => project.status !== "Active"
      }
    ];

  const generateTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: billingData?.projects
          ?.find((project) => project.id === id)
          ?.region.toLowerCase(),
        projectId: id
      }),
    [billingData, matchParams.organizationId]
  );

  const handleConfirmGenerateButtonClick = useCallback(
    (data: { start_date: string; end_date: string }) => {
      if (selectedItemId) {
        dispatch(
          billingActions.generateBillingDataReport.started({
            // startDate: formatDate(data.start_date, DATE_FORMATS.ISO_DATE),
            // endDate: formatDate(data.end_date, DATE_FORMATS.ISO_DATE),
            startDate: format(new Date(data.start_date), DATE_FORMATS.ISO_DATE),
            endDate: format(new Date(data.end_date), DATE_FORMATS.ISO_DATE),
            regionId: currentProjectRegion!,
            organizationId: matchParams.organizationId!,
            projectId: selectedItemId
          })
        );
      }
      handleCloseDialog();
    },
    [
      currentProjectRegion,
      dispatch,
      handleCloseDialog,
      matchParams.organizationId,
      selectedItemId
    ]
  );

  const handleConfirmDownloadButtonClick = useCallback(
    (data: { start_date: string; end_date: string }) => {
      if (selectedItemId) {
        const startDate = format(
          new Date(data.start_date),
          DATE_FORMATS.ISO_DATE
        );
        const endDate = format(new Date(data.end_date), DATE_FORMATS.ISO_DATE);
        downloadFile(
          `${API_PATH}gotham-wayne-tower/method/billing/organizations/${matchParams.organizationId!}/regions/${currentProjectRegion!}/projects/${selectedItemId}/download?start=${startDate}&end=${endDate}`
        );
        dispatch(
          notificationsActions.showNotification({
            title: "Billing report is generating. Download will start soon.",
            type: NOTIFICATION_TYPES.INFO
          })
        );
      }
      handleCloseDialog();
    },
    [
      selectedItemId,
      matchParams.organizationId,
      currentProjectRegion,
      dispatch,
      handleCloseDialog
    ]
  );

  useMount(() => {
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
  });

  useUnmount(() => {
    dispatch(enterprisesActions.clear());
    dispatch(billingActions.clear());
  });

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(
        billingActions.getOrganizationBillingData.started({
          startDate,
          endDate,
          id: matchParams.organizationId!
        })
      );
    }
  }, [dispatch, startDate, endDate, matchParams.organizationId]);

  const handleDateChange = useCallback(
    (dateType: DATE_TYPES) => (date: Date | null) => {
      const dateValue = date ? formatDate(date, DATE_FORMATS.ISO_DATE) : date;
      switch (dateType) {
        case DATE_TYPES.START:
          setStartDate(dateValue);
          break;
        case DATE_TYPES.END:
          setEndDate(dateValue);
      }
    },
    []
  );

  const handleCurrencyChange = useCallback((event: SelectChangeEvent) => {
    setCurrency(event.target.value);
  }, []);

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.GENERATE_BILLING_REPORT]: {
      title: `Generate billing report for the project "${currentProjectName}"`,
      // onConfirm: handleConfirmGenerateButtonClick,
      // confirmButtonLabel: "Generate",
      onConfirm: handleConfirmDownloadButtonClick,
      confirmButtonLabel: "Download",
      fields: [
        {
          name: "start_date",
          type: FIELD_TYPES.DATE_PICKER,
          label: "Start-date",
          minDate: dayjs(minDate),
          // maxDate: endDate ? new Date(endDate) : undefined,
          // helperText: `Enter start date.`,
          disableFuture: true,
          defaultValue: dayjs(startDate)
        },
        {
          name: "end_date",
          type: FIELD_TYPES.DATE_PICKER,
          label: "End-date",
          disableFuture: true,
          defaultValue: dayjs(endDate)
        },
        {
          name: "notes",
          type: FIELD_TYPES.LABEL,
          label: `ℹ️ Billing data is available for previous 120 days`
        }
        // {
        //   name: "tab",
        //   type: FIELD_TYPES.LABEL,
        //   label: `\n`
        // },
        // {
        //   name: "info",
        //   type: FIELD_TYPES.LABEL,
        //   label: `The report will be sent to your account email within a few minutes.`
        // }
        // {
        //   name: "info2",
        //   type: FIELD_TYPES.LABEL,
        //   label: `If it's delayed, please contact our support team.`
        // }
      ]
    }
  };

  return (
    <>
      <Head title={title} />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Typography variant={"h4"} component={"h2"}>
        {title}
      </Typography>
      <s.ProjectsContainer>
        <s.Description>
          ℹ️ This billing data is only for the initial reference. The final
          costs will be provided in the invoice.
        </s.Description>
        <s.Description2>
          ℹ️ Billing data is available for the last 120 days
        </s.Description2>
        <Table
          isSearchEnabled={true}
          actions={tableActions}
          isSortingEnabled={true}
          rows={tableBillingData || []}
          // rows={tableBillingData?.filter((pr) => pr.status === "Active") || []}
          // disabledRows={
          //   tableBillingData?.filter((pr) => pr.status === "Deleted") || []
          // }
          columns={[
            ...tableColumns,
            {
              key: `cost-${currency}`,
              label: "Cost",
              sortingType: TABLE_SORTING_TYPES.NUMBER
            }
          ]}
          totalRow={tableBillingDataTotal || undefined}
          itemLink={{
            column: "name",
            getURL: generateTableItemURL,
            isEnabled: (billingData) =>
              billingData.status !==
              PROJECT_STATUS_LABELS[PROJECT_STATUSES.DELETED]
          }}
          isLoading={isBillingDataLoading}
          toolbarItems={
            <>
              <DatePicker
                name={"start"}
                label={"Start date"}
                onChange={handleDateChange(DATE_TYPES.START)}
                value={startDate ? new Date(startDate) : null}
                format={DATE_FORMATS.DATE}
                minDate={minDate}
                // maxDate={endDate ? subDays(new Date(endDate), 1) : undefined}
                maxDate={endDate ? new Date(endDate) : undefined}
                disableFuture={true}
                sx={{ marginRight: "10px" }}
                slotProps={{
                  textField: {
                    InputProps: { size: "small" }
                  }
                }}
              />
              <DatePicker
                name={"end"}
                label={"End date"}
                onChange={handleDateChange(DATE_TYPES.END)}
                value={endDate ? new Date(endDate) : null}
                format={DATE_FORMATS.DATE}
                // minDate={startDate ? addDays(new Date(startDate), 1) : minDate}
                minDate={startDate ? new Date(startDate) : minDate}
                disableFuture={true}
                sx={{ marginRight: "10px" }}
                slotProps={{
                  textField: {
                    InputProps: { size: "small" }
                  }
                }}
              />
              {currencies.length > 0 && (
                <s.CurrencyFormControl>
                  <InputLabel id={CURRENCY_SELECT_ID}>Currency</InputLabel>
                  <Select
                    label={"Currency"}
                    labelId={CURRENCY_SELECT_ID}
                    value={currency}
                    onChange={handleCurrencyChange}
                    size={"small"}
                  >
                    {currencies.map((currency) => (
                      <MenuItem key={currency} value={currency}>
                        {currency.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                </s.CurrencyFormControl>
              )}
            </>
          }
        />
        <FormDialog
          isOpened={dialog.isOpened}
          onCancel={handleCloseDialog}
          fields={dialogProps[dialog.type].fields}
          onConfirm={dialogProps[dialog.type].onConfirm}
          title={dialogProps[dialog.type].title}
          confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
        />
      </s.ProjectsContainer>
    </>
  );
};

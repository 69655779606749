import { PROJECT_STATUSES } from "components/Billing/types";
import { ROLES } from "modules/enterprises/types";
import { INSTANCE_STATUSES } from "modules/instances/types";
import { VIRTUAL_MACHINE_ACTIONS } from "./types";

export const ELEMENT_IDS = {
  REACT_ROOT: "root",
  CONTENT_CONTAINER: "content-container",
  BREADCRUMBS_CONTAINER: "breadcrumbs-container"
};

export const ROUTES = {
  ROOT: "/",
  ORGANIZATIONS: "/organizations",
  ORGANIZATION_INVITATION_ACCEPTANCE:
    "/organizations/:organizationId/invitations/:invitationId/accept",
  ORGANIZATION: "/organizations/:organizationId",
  ADMINISTRATORS: "/organizations/:organizationId/administrators",
  GROUPS: "/organizations/:organizationId/groups",
  GROUP: "/organizations/:organizationId/groups/:groupId",
  GROUP_INVITATION_ACCEPTANCE:
    "/organizations/:organizationId/groups/:groupId/invitations/:invitationId/accept",
  BILLING: "/organizations/:organizationId/billing",
  PROJECT:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId",
  VIRTUAL_MACHINES:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/virtual-machines",
  VIRTUAL_MACHINE:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/virtual-machines/:virtualMachineId",
  CLUSTERS:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/clusters",
  CLUSTER:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/clusters/:clusterId",
  NETWORKING:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/networks",
  NETWORK:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/networks/:networkId",
  ROUTERS:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/networks/routers",
  ROUTER:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/networks/routers/:routerId",
  INTERFACE:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/networks/routers/:routerId/interfaces",
  SECURITY:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/security",
  FIREWALL:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/security/firewalls/:firewallId",
  FIREWALL_RULE:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/security/firewalls/:firewallId/:firewallRuleId",
  STORAGE:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/storage",
  S3_BUCKETS:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/storage/s3/:credsId",
  S3_BUCKET:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/storage/s3/:credsId/buckets/:bucketName",
  DATABASES:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/databases",
  DATABASE:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/databases/:serviceName/:databaseId",
  NLP: "/organizations/:organizationId/regions/:regionId/projects/:projectId/nlp",
  BACKUPS_WORKLOADS:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/backupsWorkloads",
  BACKUPS_WORKLOAD:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/backupsWorkloads/:workloadId",
  BACKUPS_SNAPSHOT:
    "/organizations/:organizationId/regions/:regionId/projects/:projectId/backupsWorkloads/:workloadId/backups/:snapshotId"
};

export const REGEX = {
  NAME_DOES_NOT_START_WITH_WHITESPACE: /^[^ ].*$/,
  NAME_DOES_NOT_END_WITH_WHITESPACE: /^.*[^ ]$/,
  NAME_DOES_NOT_CONTAIN_SEQUENTIAL_WHITESPACES: /^(?!.* {2}).*$/,
  NAME_STARTS_WITH_LETTER: /^[A-Za-z].*$/,
  IP_ADDRESS_WITH_NET_MASK:
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))$/, // source: https://regexr.com/3bt00
  IP_ADDRESS:
    /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/,
  PASSWORD:
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/,
  BILLING_RECORD_COST_KEY: /^cost_[a-z]{3}$/,
  BILLING_RECORD_TOTAL_COST_KEY: /^total-cost-[a-z]{3}$/,
  DATABASE_NAME: /^[A-Za-z0-9-]{1,16}$/, // This regex is used for DB's name and DB user's name
  BUCKET_NAME: /^[a-z0-9]([a-z0-9_-]*[a-z0-9])?$/,
  FOLDER_NAME: /^[^/]*$/,
  CLUSTER_NAME: /^[a-zA-Z0-9_.-]{1,255}$/,
  KEYPAIR_NAME: /^[a-zA-Z0-9_ -]+$/,
  EMAIL_ADDRESS:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // source: https://emailregex.com
  SERVER_TAG: /^[^\\.]{1,60}$/,
  ORGANIZATION_NAME: /^[^#]+$/,
  GROUP_NAME: /^[^#]+$/,
  PROJECT_NAME: /^[^#:]+$/,
  BACKUPS_NAME: /^.{0,80}$/, // any, but no longer 80
  BACKUPS_DESCRIPTION: /^.{0,1024}$/, // any, but no longer 1024
  HTTP_HTTPS_ADDRESS: /^https?:\/\/(\S+)/
};

export const ERROR_MESSAGES = {
  NAME_DOES_NOT_START_WITH_WHITESPACE: "Name must not start with whitespace.",
  NAME_DOES_NOT_END_WITH_WHITESPACE: "Name must not end with whitespace.",
  NAME_DOES_NOT_CONTAIN_SEQUENTIAL_WHITESPACES:
    "Name must not contain sequential whitespaces.",
  NAME_DOES_NOT_CONTAIN_COLON: "Name must not contain colon.",
  NAME_DOES_NOT_CONTAIN_NUMBER_SIGN: "Name must not contain number sign.",
  NAME_STARTS_WITH_LETTER: "Name must start with Latin letter (a-z, A-Z).",
  REQUIRED: "Field is required.",
  PASSWORD:
    "Password must contain at least one Latin letter in upper case (A-Z), one Latin letter in lower case (a-z), digit (0-9) and special character ( !\"#$%&'()*+,-./:;<=>?@[]^_`{|}~) and must be at least 8 characters long.",
  DATABASE_NAME:
    "Name must contain only Latin letters (a-z, A-Z), digits (0-9), hyphen (-) and must be at most 16 characters long.",
  BUCKET_NAME:
    "Name must contain only lowercase Latin letters (a-z), digits (0-9), hyphen (-), but cannot start or end with a hyphen(-).",
  MIN_BUCKET_NAME: "Name must be at least 3 characters long.",
  MAX_BUCKET_NAME: "Name cannot exceed 63 characters.",
  FOLDER_NAME: "Name cannot contain slashes (/)",
  CLUSTER_NAME:
    "Name must contain only Latin letters (a-z, A-Z), digits (0-9), hyphen (-), underscore (_) and dot(.) and must be at most 255 characters long.",
  KEYPAIR_NAME:
    "Name must contain only Latin letters (a-z, A-Z), digits (0-9), hyphen (-), underscore (_) and whitespace.",
  EMAIL_ADDRESS: "E-mail address has not valid format.",
  PASSWORDS_MUST_MATCH: "Passwords must match.",
  SERVER_TAG:
    "Server tag must not contain comma (,) or slash (/) and must be at most 60 characters long.",
  MAX_SERVER_TAG_COUNT: "Server can have up to 50 tags.",
  ORGANIZATION_NAME: "Name must not contain number sign (#).",
  GROUP_NAME: "Name must not contain number sign (#).",
  PROJECT_NAME: "Name must not contain number sign (#) or colon (:).",
  HTTP_HTTPS_ADDRESS: "Endpoint must be http or https address.",
  BACKUPS_SNAPSHOTS_RETENTION:
    "Number of backups should be in between 1 to 365.",
  BACKUPS_DAYS_RETENTION: "Number of days should be in between 1 to 365.",
  MAX_BACKUPS_NAME: "Name cannot exceed 80 characters.",
  MAX_BACKUPS_DESCRIPTION: "Description cannot exceed 1024 characters."
};

export enum DATE_FORMATS {
  ISO_DATE = "yyyy-MM-dd",
  ISO_DATETIME = "yyyy-MM-dd'T'kk:mm",
  DATE = "d MMM yyyy",
  DATETIME = "d MMM yyyy HH:mm:ss",
  TIME = "HH:mm"
}

export const DEFAULT_CURRENCY = "eur";

export const MIN_VOLUME_SIZE = 10;
export const WINDOWS_MIN_VOLUME_SIZE = 50;
export const VOLUME_SIZE_STEP = 10;
export const MIN_NODE_COUNT = 1;
export const MAX_NODE_COUNT = 10;
export const ENTITY_NAME_LENGTH = 255;
export const CLI_USER_NAME_LENGTH = 218;
export const PROJECT_NAME_LENGTH = 100;
export const MAX_SERVER_TAG_COUNT = 50;
export const MAX_DATABASE_REPLICAS_COUNT = 5;

export const VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES = {
  [VIRTUAL_MACHINE_ACTIONS.START]: [INSTANCE_STATUSES.SHUTOFF],
  [VIRTUAL_MACHINE_ACTIONS.SOFT_REBOOT]: [INSTANCE_STATUSES.ACTIVE],
  [VIRTUAL_MACHINE_ACTIONS.HARD_REBOOT]: [
    INSTANCE_STATUSES.ACTIVE,
    INSTANCE_STATUSES.ERROR,
    INSTANCE_STATUSES.HARD_REBOOT,
    INSTANCE_STATUSES.PAUSED,
    INSTANCE_STATUSES.REBOOT
    // INSTANCE_STATUSES.SHUTOFF
  ],
  [VIRTUAL_MACHINE_ACTIONS.STOP]: [
    INSTANCE_STATUSES.ACTIVE,
    INSTANCE_STATUSES.ERROR
  ],
  [VIRTUAL_MACHINE_ACTIONS.RESIZE]: [INSTANCE_STATUSES.ACTIVE],
  [VIRTUAL_MACHINE_ACTIONS.CONFIRM_RESIZE]: [INSTANCE_STATUSES.VERIFY_RESIZE],
  [VIRTUAL_MACHINE_ACTIONS.REVERT_RESIZE]: [INSTANCE_STATUSES.VERIFY_RESIZE]
};

export const VIRTUAL_MACHINE_ACTIONS_NOT_ALLOWED_STATUSES = {
  [VIRTUAL_MACHINE_ACTIONS.REMOTE_CONSOLE]: [INSTANCE_STATUSES.SHUTOFF]
};

export const ROLE_LABELS = {
  [ROLES.ADMIN]: "Administrator",
  [ROLES.MEMBER]: "Member",
  [ROLES.OWNER]: "Owner"
};

export const PROJECT_STATUS_LABELS = {
  [PROJECT_STATUSES.ACTIVE]: "Active",
  [PROJECT_STATUSES.DELETED]: "Deleted"
};

export const SPECIAL_NAMES = {
  NETWORK: "public",
  FIREWALL: "default",
  FOLDER: "../"
};

export const DEFAULT_FLAVOR = "VC-4";
export const MIN_PORT = 1;
export const MAX_PORT = 65535;
export const MIN_ICMP_TYPE = 0;
export const MAX_ICMP_TYPE = 255;
export const MIN_ICMP_CODE = 0;
export const MAX_ICMP_CODE = 255;
export const DATABASE_METRIC_DEFAULT_RANGE = 60; // in minutes
export const DATABASE_METRIC_DEFAULT_DURATION = 30; // in minutes

export enum OS_PLATFORMS {
  LINUX = "linux",
  WINDOWS = "windows"
}

export const OS_PLATFORMS_LABELS = {
  [OS_PLATFORMS.LINUX]: "Linux",
  [OS_PLATFORMS.WINDOWS]: "Windows"
};

export enum FLAVORS_TYPES {
  REGULAR = "regular",
  GPU = "gpu"
}

export const FLAVORS_TYPES_LABELS = {
  [FLAVORS_TYPES.REGULAR]: "Regular Flavor",
  [FLAVORS_TYPES.GPU]: "GPU Flavor"
};

export const DEFAULT_VOLUME_SIZE = {
  [OS_PLATFORMS.LINUX]: 50,
  [OS_PLATFORMS.WINDOWS]: 100
};

export enum LINUX_DISTROS {
  UBUNTU = "ubuntu",
  REDHAT = "rhel",
  DEBIAN = "debian",
  FEDORA = "fedora",
  CENTOS = "centos",
  // OPENSUSE = "opensuse",
  OTHER = "other"
}

export const DEFAULT_PAGINATION_LIMIT = 10;
export const MAX_PAGINATION_LIMIT = 1000;
export const DEFAULT_PAGINATION_LIMIT_FOR_S3 = 100;

export const MAX_FILE_SIZE: number = 5 * 1024 * 1024 * 1024; // 5 GB to upload to s3

// contain client's time zone in IANA TZ format
// https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
export const CLIENT_TIME_ZONE =
  Intl.DateTimeFormat().resolvedOptions().timeZone;

import WarningIcon from "@mui/icons-material/Warning";
import Button from "@mui/material/Button";
import { appConfig } from "../../appConfig";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption,
  SelectOptionWIthIcon
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { KeyPairDialog } from "components/common/KeyPairDialog";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { CentosIcon } from "components/common/icons/distros/CentosIcon";
import { DebianIcon } from "components/common/icons/distros/DebianIcon";
import { FedoraIcon } from "components/common/icons/distros/FedoraIcon";
import { OtherIcon } from "components/common/icons/distros/OtherIcon";
import { RedhatIcon } from "components/common/icons/distros/RedhatIcon";
import { UbuntuIcon } from "components/common/icons/distros/UbuntuIcon";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as instancesActions from "modules/instances/actions";
import {
  SOURCE_TYPES_LABELS,
  isInstanceCreatingSelector,
  isInstanceDeletingSelector,
  isInstanceResizeConfirmingSelector,
  isInstanceResizeRevertingSelector,
  isInstanceResizingSelector,
  isInstanceUpdatingSelector,
  selectOptionFlavorsSelector,
  selectOptionGPUFlavorsSelector,
  selectOptionRegularFlavorsSelector,
  tableImagesSelector,
  tableInterfacesSelector,
  tableVirtualMachinesSelector
} from "modules/instances/selectors";
import {
  FLAVOR_TYPE,
  INSTANCE_STATUSES,
  RESTART_TYPE,
  SOURCE_TYPES,
  TableVirtualMachine
} from "modules/instances/types";
import * as networksActions from "modules/networks/actions";
import {
  firewallsSelector,
  floatingIPsSelector,
  interfacesSelector,
  isFloatingIPUpdatingSelector,
  tableNetworksSelector
} from "modules/networks/selectors";
import * as notficationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import * as securityActions from "modules/security/actions";
import {
  isKeyPairCreatingSelector,
  keyPairSelector,
  keyPairsSelector
} from "modules/security/selectors";
import * as storageActions from "modules/storage/actions";
import {
  tableSnapshotsSelector,
  tableVolumesSelector
} from "modules/storage/selectors";
import { VOLUME_STATUSES } from "modules/storage/types";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { isString } from "typeGuards/isString";
import { downloadFile } from "utils/downloadFile";
import { getSelectDefaultValue } from "utils/getSelectDefaultValue";
import { getSelectOption } from "utils/getSelectOption";
import { validateName } from "utils/validateName";
import {
  NumberSchema,
  Schema,
  StringSchema,
  array,
  mixed,
  number,
  ref,
  string
} from "yup";
import { API_PATH } from "../../axios";
import {
  DEFAULT_FLAVOR,
  DEFAULT_VOLUME_SIZE,
  ENTITY_NAME_LENGTH,
  ERROR_MESSAGES,
  FLAVORS_TYPES,
  FLAVORS_TYPES_LABELS,
  LINUX_DISTROS,
  MAX_SERVER_TAG_COUNT,
  MIN_VOLUME_SIZE,
  OS_PLATFORMS,
  OS_PLATFORMS_LABELS,
  REGEX,
  ROUTES,
  SPECIAL_NAMES,
  VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES,
  VIRTUAL_MACHINE_ACTIONS_NOT_ALLOWED_STATUSES,
  VOLUME_SIZE_STEP,
  WINDOWS_MIN_VOLUME_SIZE
} from "../../constants";
import { VIRTUAL_MACHINE_ACTIONS } from "../../types";
import { DIALOG_TYPES } from "./types";

const POLL_ID_PREFIX = "VIRTUAL_MACHINES";

const POLL_IDS = {
  instances: "INSTANCES",
  flavors: "FLAVORS",
  images: "IMAGES",
  keyPairs: "KEY_PAIRS",
  networks: "NETWORKS",
  firewalls: "FIREWALLS",
  volumes: "VOLUMES",
  snapshots: "SNAPSHOTS",
  interfaces: "INTERFACES",
  floatingIPs: "FLOATING_IPs"
};

const title = "Virtual machines";

export const VirtualMachines: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
  }>();
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const virtualMachines = useSelector(tableVirtualMachinesSelector);
  const flavors = useSelector(selectOptionFlavorsSelector);
  const gpuFlavors = useSelector(selectOptionGPUFlavorsSelector);
  const regularFlavors = useSelector(selectOptionRegularFlavorsSelector);

  const images = useSelector(tableImagesSelector);
  const volumes = useSelector(tableVolumesSelector);
  const snapshots = useSelector(tableSnapshotsSelector);
  const networks = useSelector(tableNetworksSelector);
  const interfaces = useSelector(interfacesSelector);
  const keyPairs = useSelector(keyPairsSelector);
  const firewalls = useSelector(firewallsSelector);
  const tableInterfaces = useSelector(tableInterfacesSelector);
  const floatingIPs = useSelector(floatingIPsSelector);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE, isOpened: false });
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const isVirtualMachineCreating = useSelector(isInstanceCreatingSelector);
  const isVirtualMachineUpdating = useSelector(isInstanceUpdatingSelector);
  const isVirtualMachineResizing = useSelector(isInstanceResizingSelector);
  const isFloatingIPUpdating = useSelector(isFloatingIPUpdatingSelector);
  const isVirtualMachineResizeConfirming = useSelector(
    isInstanceResizeConfirmingSelector
  );
  const isVirtualMachineResizeReverting = useSelector(
    isInstanceResizeRevertingSelector
  );
  const isVirtualMachineDeleting = useSelector(isInstanceDeletingSelector);
  const isOperationInProgress =
    isVirtualMachineCreating ||
    isVirtualMachineUpdating ||
    isVirtualMachineResizing ||
    isVirtualMachineResizeConfirming ||
    isVirtualMachineResizeReverting ||
    isFloatingIPUpdating ||
    isVirtualMachineDeleting;
  const previousIsOperationInProgress = usePrevious(isOperationInProgress);
  const keyPair = useSelector(keyPairSelector);
  const previousKeyPair = usePrevious(keyPair);
  const isKeyPairCreating = useSelector(isKeyPairCreatingSelector);
  const isPreviousKeyPairCreating = usePrevious(isKeyPairCreating);
  const [isKeyPairDialogOpened, setIsKeyPairDialogOpened] = useState(false);
  const [isCreateKeyPairDialogOpened, setIsCreateKeyPairDialogOpened] =
    useState(false);

  // to hide tags column if it is empty for all vms
  const [allTagsEmpty, setAllTagsEmpty] = useState<boolean>(true);
  useEffect(() => {
    const isEmpty =
      virtualMachines?.every((vm) => vm.tags.length === 0) ?? true;
    setAllTagsEmpty(isEmpty);
  }, [virtualMachines]);

  const tableColumns: TableColumn<TableVirtualMachine>[] = [
    { key: "name", label: "Name" },
    ...(allTagsEmpty
      ? []
      : [{ key: "tagsString" as keyof TableVirtualMachine, label: "Tags" }]),
    { key: "status", label: "Status" },
    { key: "ip", label: "IP Address" },
    { key: "keypair", label: "Key Pair" },
    { key: "vCPUs", label: "vCPUs" },
    {
      key: "memory",
      label: "Memory",
      sortingType: TABLE_SORTING_TYPES.NUMBER
    },
    {
      key: "created",
      label: "Created",
      sortingType: TABLE_SORTING_TYPES.DATE
    }
  ];

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Virtual machines",
      url: generatePath(ROUTES.VIRTUAL_MACHINES, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    }
  ];

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.instances}`,
        action: instancesActions.getInstances.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.flavors}`,
        action: instancesActions.getFlavors.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          type: FLAVOR_TYPE.INSTANCE
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.images}`,
        action: instancesActions.getImages.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.volumes}`,
        action: storageActions.getVolumes.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.snapshots}`,
        action: storageActions.getSnapshots.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.keyPairs}`,
        action: securityActions.getKeyPairs.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.networks}`,
        action: networksActions.getNetworks.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.interfaces}`,
        action: networksActions.getInterfaces.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.floatingIPs}`,
        action: networksActions.getFloatingIPs.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.firewalls}`,
        action: networksActions.getFirewalls.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
    dispatch(instancesActions.clear());
    dispatch(networksActions.clear());
    dispatch(securityActions.clear());
  });

  // show notifications if VM has status VERIFY_RESIZE
  const virtualMachinesVerifyResize = virtualMachines?.filter(
    (vm) => vm.status === INSTANCE_STATUSES.VERIFY_RESIZE
  );

  useEffect(() => {
    if (virtualMachinesVerifyResize?.length) {
      dispatch(
        notficationsActions.showNotification({
          type: NOTIFICATION_TYPES.INFO,
          title: "Action required. Please confirm or revert resize ..."
        })
      );
    }
  }, [dispatch, virtualMachinesVerifyResize?.length]);

  useEffect(() => {
    if (previousIsOperationInProgress && !isOperationInProgress) {
      dispatch(
        instancesActions.getInstances.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    previousIsOperationInProgress,
    isOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  useEffect(() => {
    if (isPreviousKeyPairCreating && !isKeyPairCreating) {
      dispatch(
        securityActions.getKeyPairs.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [
    isPreviousKeyPairCreating,
    isKeyPairCreating,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  useEffect(() => {
    if (selectedItemId) {
      dispatch(
        instancesActions.getInterfaces.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          instanceId: selectedItemId
        })
      );
      dispatch(
        networksActions.getFloatingIPs.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      );
    }
  }, [selectedItemId, dispatch, matchParams.regionId, matchParams.projectId]);

  const generateTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.VIRTUAL_MACHINE, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        virtualMachineId: id
      }),
    [matchParams.projectId, matchParams.organizationId, matchParams.regionId]
  );

  const handleCreateVirtualMachineButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE,
      isOpened: true
    });
  }, []);

  const handleAssociateFloatingIPMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.ASSOCIATE_FLOATING_IP,
      isOpened: true
    });
  }, []);

  const handleDisassociateFloatingIPMenuItemClick = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      setDialog({
        type: DIALOG_TYPES.DISASSOCIATE_FLOATING_IP,
        isOpened: true
      });
    },
    []
  );

  const handleDownloadRDPFileMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DOWNLOAD_RDP_FILE,
      isOpened: true
    });
  }, []);

  const handleStartVirtualMachineButtonClick = useCallback(
    (id: string) => {
      dispatch(
        instancesActions.startInstance.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id
        })
      );
    },
    [dispatch, matchParams.projectId, matchParams.regionId]
  );

  const handleStopVirtualMachineButtonClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.STOP,
      isOpened: true
    });
  }, []);

  const handleSoftRebootButtonClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.SOFT_REBOOT,
      isOpened: true
    });
  }, []);

  const handleEditVirtualMachineMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.EDIT,
      isOpened: true
    });
  }, []);

  const handleResizeVirtualMachineMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.RESIZE,
      isOpened: true
    });
  }, []);

  const handleConfirmResizeVirtualMachineMenuItemClick = useCallback(
    (id: string) => {
      dispatch(
        instancesActions.confirmResizeInstance.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id
        })
      );
    },
    [dispatch, matchParams.projectId, matchParams.regionId]
  );

  const handleRevertResizeVirtualMachineMenuItemClick = useCallback(
    (id: string) => {
      dispatch(
        instancesActions.revertResizeInstance.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id
        })
      );
    },
    [dispatch, matchParams.projectId, matchParams.regionId]
  );

  const handleDeleteVirtualMachineMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE,
      isOpened: true
    });
  }, []);

  const handleGetRemoteConsoleURLButtonClick = useCallback(
    (id: string) => {
      dispatch(
        instancesActions.getRemoteConsoleURL.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          instanceId: id
        })
      );
    },
    [dispatch, matchParams.projectId, matchParams.regionId]
  );

  const tableActions: TableRowActionsMenuItem<TableVirtualMachine>[] = [
    {
      label: "Associate Floating IP",
      handler: handleAssociateFloatingIPMenuItemClick,
      isDisabled: (tableVM) => tableVM.ip.includes(",")
    },
    {
      label: "Disassociate Floating IP",
      handler: handleDisassociateFloatingIPMenuItemClick,
      isDisabled: (tableVM) => !tableVM.ip.includes(",")
    },
    {
      label: "Download RDP file",
      handler: handleDownloadRDPFileMenuItemClick,
      isDisabled: (virtualMachine) =>
        !isImagePlaform(virtualMachine.imageId, OS_PLATFORMS.WINDOWS)
    },
    {
      label: "Start",
      handler: handleStartVirtualMachineButtonClick,
      isDisabled: (virtualMachine) =>
        !VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES[
          VIRTUAL_MACHINE_ACTIONS.START
        ].includes(virtualMachine.status)
    },

    {
      label: "Stop",
      handler: handleStopVirtualMachineButtonClick,
      isDisabled: (virtualMachine) =>
        !VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES[
          VIRTUAL_MACHINE_ACTIONS.STOP
        ].includes(virtualMachine.status)
    },
    {
      label: "Soft reboot",
      handler: handleSoftRebootButtonClick,
      isDisabled: (virtualMachine) =>
        !VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES[
          VIRTUAL_MACHINE_ACTIONS.SOFT_REBOOT
        ].includes(virtualMachine.status)
    },

    {
      label: "Edit",
      handler: handleEditVirtualMachineMenuItemClick
    },
    {
      label: "Resize",
      handler: handleResizeVirtualMachineMenuItemClick,
      isDisabled: (virtualMachine) =>
        !VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES[
          VIRTUAL_MACHINE_ACTIONS.RESIZE
        ].includes(virtualMachine.status)
    },
    {
      label: "Confirm resize",
      handler: handleConfirmResizeVirtualMachineMenuItemClick,
      isDisabled: (virtualMachine) =>
        !VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES[
          VIRTUAL_MACHINE_ACTIONS.CONFIRM_RESIZE
        ].includes(virtualMachine.status)
    },
    {
      label: "Revert resize",
      handler: handleRevertResizeVirtualMachineMenuItemClick,
      isDisabled: (virtualMachine) =>
        !VIRTUAL_MACHINE_ACTIONS_ALLOWED_STATUSES[
          VIRTUAL_MACHINE_ACTIONS.REVERT_RESIZE
        ].includes(virtualMachine.status)
    },
    {
      label: "Delete",
      handler: handleDeleteVirtualMachineMenuItemClick
    },
    {
      label: "Remote console",
      handler: handleGetRemoteConsoleURLButtonClick,
      isDisabled: (virtualMachine) =>
        VIRTUAL_MACHINE_ACTIONS_NOT_ALLOWED_STATUSES[
          VIRTUAL_MACHINE_ACTIONS.REMOTE_CONSOLE
        ].includes(virtualMachine.status)
    }
  ];

  const LinuxDistros: SelectOptionWIthIcon[] = [
    {
      label: "Ubuntu",
      value: LINUX_DISTROS.UBUNTU,
      icon: <UbuntuIcon />
    },
    {
      label: "RedHat",
      value: LINUX_DISTROS.REDHAT,
      icon: <RedhatIcon />
    },
    {
      label: "CentOS",
      value: LINUX_DISTROS.CENTOS,
      icon: <CentosIcon />
    },
    {
      label: "Debian",
      value: LINUX_DISTROS.DEBIAN,
      icon: <DebianIcon />
    },
    // {
    //   label: "OpenSuse",
    //   value: LINUX_DISTROS.OPENSUSE,
    //   icon: <OpenSuseIcon />
    // },
    {
      label: "Fedora",
      value: LINUX_DISTROS.FEDORA,
      icon: <FedoraIcon />
    },
    {
      label: "Other",
      value: LINUX_DISTROS.OTHER,
      icon: <OtherIcon />
    }
  ];

  const handleConfirmCreateVirtualMachine = useCallback(
    (data: {
      name: string;
      flavorRef: SelectOption;
      source_type: string;
      linux_distros: string;
      sourceRef: SelectOption;
      key_name?: SelectOption;
      default_username?: string;
      networks: SelectOption[];
      volume_size: number;
      delete_on_termination: boolean;
      firewalls: SelectOption[];
      password?: string;
      tags: string[];
      count: number;
    }) => {
      dispatch(
        instancesActions.createInstance.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          data: {
            name: data.name,
            tags: data.tags,
            block_device_mapping_v2: {
              delete_on_termination: data.delete_on_termination,
              volume_size: Number(data.volume_size)
            },
            flavorRef: data.flavorRef.value,
            source_type: data.source_type,
            linux_distros: data.linux_distros,
            sourceRef: data.sourceRef.value,
            count: data.count,
            ...(data.key_name?.value ? { key_name: data.key_name.value } : {}),
            networks: data.networks.map((network) => ({
              uuid: network.value
            })),
            ...(data.firewalls.length > 0
              ? { firewalls: data.firewalls.map((firewall) => firewall.value) }
              : {}),
            ...(data.password ? { password: data.password } : {})
          }
        })
      );
      handleCloseDialog();
    },
    [dispatch, matchParams.projectId, handleCloseDialog, matchParams.regionId]
  );

  const handleConfirmAssociateFloatingIP = useCallback(
    (data: { interface: SelectOption; floatingIP: SelectOption }) => {
      if (selectedItemId) {
        dispatch(
          networksActions.updateFloatingIP.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: data.floatingIP.value,
            data: {
              ...data,
              port_id: data.interface.value
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmDisassociateFloatingIP = useCallback(() => {
    if (selectedItemId && virtualMachines && floatingIPs && interfaces) {
      dispatch(
        networksActions.updateFloatingIP.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id:
            floatingIPs.find((fip) => {
              if (interfaces.find((i) => i.id === fip.port_id)) {
                return fip;
              }
            })?.id || "",
          data: {
            port_id: ""
          }
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    virtualMachines,
    floatingIPs,
    interfaces,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.projectId
  ]);

  const handleConfirmDownloadRDPFileMenuItemClick = useCallback(
    (data: { ip_address: SelectOption }) => {
      if (selectedItemId) {
        downloadFile(
          `${API_PATH}gotham-${matchParams.regionId}-compute/method/${matchParams.projectId}/instances/${selectedItemId}/rdpfile?ip_address=${data.ip_address.value}`
        );
      }
      handleCloseDialog();
    },
    [
      matchParams.regionId,
      matchParams.projectId,
      selectedItemId,
      handleCloseDialog
    ]
  );

  const handleConfirmStopVirtualMachine = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        instancesActions.stopInstance.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    dispatch,
    selectedItemId,
    matchParams.regionId,
    matchParams.projectId,
    handleCloseDialog
  ]);

  const handleConfirmSoftRebootVirtualMachine = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        instancesActions.restartInstance.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: selectedItemId,
          type: RESTART_TYPE.SOFT
        })
      );
    }
    handleCloseDialog();
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    matchParams.regionId,
    handleCloseDialog
  ]);

  const handleConfirmEditVirtualMachine = useCallback(
    (data: { name: string; tags: string[] }) => {
      if (selectedItemId) {
        dispatch(
          instancesActions.updateInstance.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmResizeVirtualMachine = useCallback(
    (data: { flavor: SelectOption }) => {
      if (selectedItemId) {
        dispatch(
          instancesActions.resizeInstance.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            flavorId: data.flavor.value
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      selectedItemId,
      matchParams.projectId,
      matchParams.regionId,
      handleCloseDialog
    ]
  );

  const handleConfirmDeleteVirtualMachine = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        instancesActions.deleteInstance.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const handleCreateKeyPair = useCallback(() => {
    setIsCreateKeyPairDialogOpened(true);
  }, []);

  const handleCloseCreateKeyPairDialog = useCallback(() => {
    setIsCreateKeyPairDialogOpened(false);
  }, []);

  const handleConfirmCreateKeyPair = useCallback(
    (data: { name: string }) => {
      dispatch(
        securityActions.createKeyPair.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          data
        })
      );
      handleCloseCreateKeyPairDialog();
    },
    [
      dispatch,
      handleCloseCreateKeyPairDialog,
      matchParams.projectId,
      matchParams.regionId
    ]
  );

  const handleCloseKeyPairDialog = useCallback(() => {
    setIsKeyPairDialogOpened(false);
  }, []);

  const isImagePlaform = useCallback(
    (id: string, platform: OS_PLATFORMS): boolean =>
      images?.find((image) => image.id === id)?.osPlatform === platform,
    [images]
  );

  useEffect(() => {
    if (previousKeyPair !== keyPair && keyPair) {
      setIsKeyPairDialogOpened(true);
    }
  }, [
    previousKeyPair,
    keyPair,
    dispatch,
    matchParams.projectId,
    matchParams.regionId
  ]);

  // to add entity name to the delete confirmation window
  const previousSelectedItemId = usePrevious(selectedItemId);
  const currentItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const currentVirtualMachineName = virtualMachines?.find(
    (virtualMachine) => virtualMachine.id === currentItemId
  )?.name;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE]: {
      onConfirm: handleConfirmCreateVirtualMachine,
      title: "Create virtual machine",
      confirmButtonLabel: "Create",
      isLocked: isKeyPairCreating,
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "source_type",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Source",
          helperText: "Choose Source Type",
          options: Object.keys(SOURCE_TYPES_LABELS).map((x) => ({
            label: SOURCE_TYPES_LABELS[x],
            value: x
          })),
          defaultValue: SOURCE_TYPES.IMAGE,
          dependent_fields: ["sourceRef"],
          rules: string().required()
        },
        {
          name: "os_platform",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "OS platform",
          helperText: "Choose OS Platform",
          options: Object.keys(OS_PLATFORMS_LABELS).map((x) => ({
            label: OS_PLATFORMS_LABELS[x],
            value: x
          })),
          defaultValue: OS_PLATFORMS.LINUX,
          dependent_fields: ["sourceRef"],
          rules: string()
        },
        {
          name: "linux_distros",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Linux Distributions",
          helperText: "Choose Distribution",
          options: LinuxDistros,
          defaultValue: LINUX_DISTROS.UBUNTU,
          rules: string(),
          dependent_fields: ["sourceRef"],
          isHidden: (fieldValues) => {
            return (
              !fieldValues.os_platform ||
              fieldValues.os_platform === OS_PLATFORMS.WINDOWS ||
              fieldValues.source_type !== SOURCE_TYPES.IMAGE
            );
          }
        },
        {
          name: "sourceRef",
          type: FIELD_TYPES.SELECT,
          label: (fieldValues) => {
            switch (fieldValues.source_type) {
              case SOURCE_TYPES.IMAGE:
                return "Image Version";
              case SOURCE_TYPES.VOLUME:
                return "Volume name";
              case SOURCE_TYPES.SNAPSHOT:
                return "Snapshot name";
              default:
                return "Choose Source";
            }
          },
          options: (fieldValues) => {
            switch (fieldValues.source_type) {
              case SOURCE_TYPES.VOLUME:
                return volumes
                  ?.filter(
                    (volume) => volume.status === VOLUME_STATUSES.AVAILABLE
                  )
                  .map((volume) =>
                    getSelectOption(volume, "detailedName", "id")
                  );
              case SOURCE_TYPES.SNAPSHOT:
                return snapshots?.map((snapshot) =>
                  getSelectOption(snapshot, "name", "id")
                );
              case SOURCE_TYPES.IMAGE: {
                if (fieldValues.os_platform === OS_PLATFORMS.WINDOWS) {
                  return images
                    ?.filter(
                      (image) =>
                        !image.isDeprecated &&
                        image.osPlatform === fieldValues.os_platform
                    )
                    .map((image) => getSelectOption(image, "name", "id"));
                }
                switch (fieldValues.linux_distros) {
                  case LINUX_DISTROS.CENTOS:
                  case LINUX_DISTROS.DEBIAN:
                  case LINUX_DISTROS.FEDORA:
                  case LINUX_DISTROS.REDHAT:
                  case LINUX_DISTROS.UBUNTU:
                    return images
                      ?.filter(
                        (image) =>
                          !image.isDeprecated &&
                          image.osDistro.toLowerCase() ===
                            fieldValues.linux_distros
                      )
                      .map((image) => getSelectOption(image, "name", "id"));
                  //  .sort((a, b) => b.name.localeCompare(a.name))
                  case LINUX_DISTROS.OTHER:
                    return images
                      ?.filter((image) => {
                        const distro = image.osDistro.toLowerCase();
                        return (
                          !image.isDeprecated &&
                          ((distro as LINUX_DISTROS) === LINUX_DISTROS.OTHER ||
                            (!Object.values(LINUX_DISTROS).some(
                              (value) => value === (distro as LINUX_DISTROS)
                            ) &&
                              image.osPlatform === fieldValues.os_platform) ||
                            image.osPlatform === "")
                        );
                      })
                      .map((image) => getSelectOption(image, "name", "id"));
                  default:
                    return [];
                }
              }
            }
          },
          rules: selectOptionSchema,
          isHidden: (fieldValues) => !fieldValues.source_type
        },
        {
          name: "default_username",
          type: FIELD_TYPES.NOTES,
          label: ({ sourceRef, os_platform, source_type }) => {
            let username = "";
            if (sourceRef && os_platform) {
              const selectedImage = sourceRef as SelectOption;
              const selectedImageId = selectedImage?.value;
              const image = images?.find(
                (image) => image.id === selectedImageId
              );
              const defaultUser = image?.osDefaultUser || "";
              username = source_type === SOURCE_TYPES.IMAGE ? defaultUser : "";
            }
            return username ? `✍ default username is "${username}"\n\n` : "";
          }
        },
        {
          name: "flavorType",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Flavor Type",
          helperText: "Choose flavor type",
          options: Object.keys(FLAVORS_TYPES_LABELS).map((x) => ({
            label: FLAVORS_TYPES_LABELS[x],
            value: x
          })),
          defaultValue: FLAVORS_TYPES.REGULAR,
          dependent_fields: ["flavorRef"],
          isHidden: () => {
            return !appConfig.gpuEnabledRegions.includes(matchParams.regionId!);
          },
          rules: string()
        },
        {
          name: "gpuFlavorInfo",
          type: FIELD_TYPES.NOTES,
          label:
            "\n⚠️ This project is currently not associated with GPU flavors\n\nℹ️ Please contact support to request access to GPU\n\n",
          isHidden: (fieldValues) => {
            return (
              !fieldValues.flavorType ||
              fieldValues.flavorType !== FLAVORS_TYPES.GPU ||
              !gpuFlavors ||
              gpuFlavors.length >= 1
            );
          }
        },
        {
          name: "flavorRef",
          type: FIELD_TYPES.SELECT,
          label: "Flavor",
          options: (fieldValues) => {
            if (
              fieldValues.flavorType &&
              fieldValues.flavorType === FLAVORS_TYPES.REGULAR
            ) {
              return regularFlavors?.map((flavor) =>
                getSelectOption(flavor, "name", "id")
              );
            }

            if (
              fieldValues.flavorType &&
              fieldValues.flavorType !== FLAVORS_TYPES.REGULAR
            ) {
              return gpuFlavors?.map((flavor) =>
                getSelectOption(flavor, "name", "id")
              );
            }
            return flavors?.map((flavor) =>
              getSelectOption(flavor, "name", "id")
            );
          },
          defaultValue: () => {
            const default_flavor = regularFlavors?.find((flavor) =>
              flavor.name.includes(DEFAULT_FLAVOR)
            );
            return default_flavor
              ? getSelectOption(default_flavor, "name", "id")
              : null;
          },

          rules: selectOptionSchema
        },
        {
          name: "key_name",
          type: FIELD_TYPES.SELECT,
          label: "Key pair",
          options: keyPairs?.map((keyPair) =>
            getSelectOption(keyPair, "name", "id")
          ),
          onCreate: handleCreateKeyPair,
          createText: "Create new key pair",
          defaultValue:
            keyPairs?.length === 1
              ? getSelectOption(keyPairs[0], "name", "id")
              : null,
          rules: selectOptionSchema.when(
            "os_platform",
            // (os_platform: string | null, schema: SchemaOf<SelectOption>) =>
            (os_platform: string[], schema: Schema<SelectOption>) =>
              os_platform[0] === (OS_PLATFORMS.WINDOWS as string)
                ? mixed().notRequired()
                : schema
          ),
          isHidden: (fieldValues) => {
            return (
              !fieldValues.os_platform ||
              fieldValues.os_platform === OS_PLATFORMS.WINDOWS
            );
          }
        },
        {
          name: "password",
          type: FIELD_TYPES.PASSWORD,
          label: "Password",
          autocomplete: "new-password",
          rules: string().when(
            "os_platform",
            // (os_platform: string | null, schema: StringSchema) => {
            (os_platform: string[], schema: StringSchema) => {
              return os_platform[0] === (OS_PLATFORMS.WINDOWS as string)
                ? schema
                    .required()
                    .matches(REGEX.PASSWORD, ERROR_MESSAGES.PASSWORD)
                : mixed().notRequired();
            }
          ),
          isHidden: (fieldValues) => {
            return (
              !fieldValues.os_platform ||
              fieldValues.os_platform !== OS_PLATFORMS.WINDOWS
            );
          }
        },
        {
          name: "confirmPassword",
          type: FIELD_TYPES.PASSWORD,
          label: "Confirm password",
          autocomplete: "new-password",
          rules: string().oneOf(
            [ref("password")],
            ERROR_MESSAGES.PASSWORDS_MUST_MATCH
          ),
          isHidden: (fieldValues) => {
            return (
              !fieldValues.os_platform ||
              fieldValues.os_platform !== OS_PLATFORMS.WINDOWS
            );
          }
        },
        {
          name: "networks",
          type: FIELD_TYPES.MULTISELECT,
          label: "Networks",
          options: networks?.map((network) =>
            getSelectOption(network, "name", "id")
          ),
          defaultValue: () => {
            const publicNet = networks?.find(
              (net) => net.name === SPECIAL_NAMES.NETWORK
            );
            if (!publicNet) return null;
            return [getSelectOption(publicNet, "name", "id")];
          },
          rules: array(selectOptionSchema).min(1, ERROR_MESSAGES.REQUIRED)
        },
        {
          name: "firewalls",
          type: FIELD_TYPES.MULTISELECT,
          label: "Firewalls",
          options: firewalls?.map((firewall) =>
            getSelectOption(firewall, "name", "id")
          ),
          defaultValue: () => {
            const defaultFirewall = firewalls?.find(
              (firewall) => firewall.name === SPECIAL_NAMES.FIREWALL
            );
            if (!defaultFirewall) return null;
            return [getSelectOption(defaultFirewall, "name", "id")];
          },
          rules: array(selectOptionSchema).min(1, ERROR_MESSAGES.REQUIRED)
        },
        {
          name: "volume_size",
          type: FIELD_TYPES.NUMBER,
          label: "Volume size",
          suffix: "GiB",
          min: MIN_VOLUME_SIZE,
          step: VOLUME_SIZE_STEP,
          defaultValue: 50,
          rules: number()
            .integer()
            .test("dynamicMin", function (value) {
              const { os_platform } = this.parent;
              const minSize =
                os_platform === OS_PLATFORMS.WINDOWS
                  ? WINDOWS_MIN_VOLUME_SIZE
                  : MIN_VOLUME_SIZE;

              if (value === undefined || value < minSize) {
                return this.createError({
                  message: `Volume size must be greater than or equal to ${minSize} GiB.`
                });
              }
              return true;
            })
            .test(
              "isMultipleOf",
              `Volume size must be multiple of ${VOLUME_SIZE_STEP}`,
              (value) => Boolean(value && value % VOLUME_SIZE_STEP === 0)
            ),
          // .required(),
          isHidden: (fieldValues) => {
            return (
              !fieldValues.os_platform ||
              fieldValues.source_type === SOURCE_TYPES.VOLUME
            );
          }
        },
        {
          name: "count",
          type: FIELD_TYPES.NUMBER,
          defaultValue: 1,
          min: 1,
          label: "Number of instances",
          helperText: "Deploy multiple VMs with the same configuration",
          rules: number()
            .integer()
            .min(1, "Please ensure the value is 1 or greater.")
        },
        {
          name: "tags",
          type: FIELD_TYPES.MULTISELECT,
          label: "Tags",
          options: [],
          freeSolo: true,
          rules: array(
            string().matches(REGEX.SERVER_TAG, ERROR_MESSAGES.SERVER_TAG)
          ).max(MAX_SERVER_TAG_COUNT, ERROR_MESSAGES.MAX_SERVER_TAG_COUNT),
          helperText: 'Press "Enter" to add a new tag'
        },
        {
          name: "delete_on_termination",
          type: FIELD_TYPES.CHECKBOX,
          label: "Delete volume after VM deletion"
        }
      ]
    },
    [DIALOG_TYPES.ASSOCIATE_FLOATING_IP]: {
      onConfirm: handleConfirmAssociateFloatingIP,
      title: "Associate floating IP",
      confirmButtonLabel: "Associate",
      fields: [
        {
          name: "interface",
          type: FIELD_TYPES.SELECT,
          helperText:
            "It's not allowed to associate floating IP with a public  one",
          label: "Interface",
          options: () =>
            tableInterfaces
              ?.filter((i) => i.networkName !== "public")
              .map((i) => getSelectOption(i, "ip", "id")),
          rules: selectOptionSchema
        },
        {
          name: "floatingIP",
          type: FIELD_TYPES.SELECT,
          label: "Floating IP",
          options: () =>
            floatingIPs
              ?.filter((fip) => fip.status != "ACTIVE")
              .map((fip) => getSelectOption(fip, "floating_ip_address", "id")),
          rules: selectOptionSchema
        }
      ]
    },
    [DIALOG_TYPES.DISASSOCIATE_FLOATING_IP]: {
      onConfirm: handleConfirmDisassociateFloatingIP,
      title: "Are you sure you want to disassociate floating IP with this VM?",
      confirmButtonLabel: "Disassociate"
    },
    [DIALOG_TYPES.EDIT]: {
      onConfirm: handleConfirmEditVirtualMachine,
      title: "Edit virtual machine",
      confirmButtonLabel: "Save",
      isLocked: false,
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            }),
          defaultValue:
            virtualMachines?.find(
              (virtualMachine) => virtualMachine.id === selectedItemId
            )?.name || ""
        },
        {
          name: "tags",
          type: FIELD_TYPES.MULTISELECT,
          label: "Tags",
          rules: array(
            string().matches(REGEX.SERVER_TAG, ERROR_MESSAGES.SERVER_TAG)
          ).max(MAX_SERVER_TAG_COUNT, ERROR_MESSAGES.MAX_SERVER_TAG_COUNT),
          freeSolo: true,
          options: [],
          defaultValue:
            virtualMachines?.find(
              (virtualMachine) => virtualMachine.id === selectedItemId
            )?.tags || [],
          isHidden: () =>
            virtualMachines?.find(
              (virtualMachine) => virtualMachine.id === selectedItemId
            )?.status !== INSTANCE_STATUSES.ACTIVE,
          helperText: 'Press "Enter" to add a new tag'
        }
      ]
    },
    [DIALOG_TYPES.RESIZE]: {
      onConfirm: handleConfirmResizeVirtualMachine,
      title: "Resize virtual machine",
      confirmButtonLabel: "Resize",
      fields: [
        {
          name: "flavor",
          type: FIELD_TYPES.SELECT,
          label: "Flavor",
          defaultValue: getSelectDefaultValue(
            flavors,
            virtualMachines?.find(
              (virtualMachine) => virtualMachine.id === selectedItemId
            )?.flavorId || null,
            "name"
          ),
          options: flavors?.map((flavor) =>
            getSelectOption(flavor, "name", "id")
          ),
          rules: selectOptionSchema
        }
      ]
    },
    [DIALOG_TYPES.DELETE]: {
      isLocked: false,
      onConfirm: handleConfirmDeleteVirtualMachine,
      title: `Are you sure you want to delete "${
        currentVirtualMachineName ?? "selected"
      }" virtual machine?`,
      fields: [
        {
          name: "confirmationName",
          type: FIELD_TYPES.TEXT,
          label: "Type the virtual machine name to confirm deletion",
          rules: string()
            .required()
            .test({
              name: "validateConfirmationName",
              message: "Virtual machine name does not match",
              test: function (value) {
                return value === currentVirtualMachineName;
              }
            })
        }
      ],
      confirmButtonLabel: "Delete"
    },

    [DIALOG_TYPES.STOP]: {
      onConfirm: handleConfirmStopVirtualMachine,
      title: `Are you sure you want to stop "${
        currentVirtualMachineName ?? "selected"
      }" virtual machine?`,
      confirmButtonLabel: "Stop"
    },
    [DIALOG_TYPES.SOFT_REBOOT]: {
      onConfirm: handleConfirmSoftRebootVirtualMachine,
      title: `Are you sure you want to soft reboot "${
        currentVirtualMachineName ?? "selected"
      }" virtual machine?`,
      confirmButtonLabel: "Soft reboot"
    },

    [DIALOG_TYPES.DOWNLOAD_RDP_FILE]: {
      onConfirm: handleConfirmDownloadRDPFileMenuItemClick,
      title: "Download RDP file",
      confirmButtonLabel: "Download",
      isLoading: !tableInterfaces,
      fields: [
        {
          name: "ip_address",
          type: FIELD_TYPES.SELECT,
          label: "IP Address",
          options: tableInterfaces?.map((i) => getSelectOption(i, "ip", "ip")),
          rules: selectOptionSchema
        }
      ]
    }
  };

  return (
    <>
      <Head title={title} />
      {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Typography variant={"h4"} component={"h2"}>
        {title}
      </Typography>
      <Table
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={virtualMachines || []}
        columns={tableColumns}
        actions={tableActions}
        // itemWithIcon={{
        //   column: "status",
        //   rowSpecificity: "VERIFY_RESIZE",
        //   tooltipText: "Please, confirm or revert resize",
        //   icon: <WarningIcon color="secondary" fontSize="small" />
        // }}
        itemWithIcon={{
          withText: true,
          column: "status",
          getIcon: (row) => {
            if (row["status"] === INSTANCE_STATUSES.VERIFY_RESIZE)
              return <WarningIcon color="secondary" fontSize="small" />;
            return <></>;
          },
          tooltipText: (row) => {
            if (row.status === INSTANCE_STATUSES.VERIFY_RESIZE)
              return "Please, confirm or revert resize";
            return "";
          }
        }}
        itemLink={{
          column: "name",
          getURL: generateTableItemURL
        }}
        isLoading={!virtualMachines || !flavors}
        toolbarItems={
          <Button
            onClick={handleCreateVirtualMachineButtonClick}
            variant={"contained"}
            disabled={
              !flavors ||
              !images ||
              !networks ||
              !keyPairs ||
              !firewalls ||
              !volumes ||
              !snapshots
            }
          >
            Create VM
          </Button>
        }
      />
      <FormDialog
        isOpened={dialog.isOpened}
        isLoading={dialogProps[dialog.type].isLoading}
        onCancel={handleCloseDialog}
        isLocked={dialogProps[dialog.type].isLocked}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
      <FormDialog
        isOpened={isCreateKeyPairDialogOpened}
        onCancel={handleCloseCreateKeyPairDialog}
        fields={[
          {
            name: "name",
            type: FIELD_TYPES.TEXT,
            label: "Name",
            rules: string()
              .required()
              .test({
                name: "validateName",
                test: validateName(ENTITY_NAME_LENGTH)
              })
              .test(
                "sameNameCheckFormat",
                "This name already exists",
                (value) => {
                  const name = keyPairs?.find(
                    (keypair) => keypair.id === value
                  )?.name;
                  return Boolean(value && !name);
                }
              )
          }
        ]}
        onConfirm={handleConfirmCreateKeyPair}
        title={"Create SSH key"}
        confirmButtonLabel={"Create"}
      />
      {keyPair && isString(keyPair.private_key) && (
        <KeyPairDialog
          isOpened={isKeyPairDialogOpened}
          name={keyPair.name}
          privateKey={keyPair.private_key}
          onClose={handleCloseKeyPairDialog}
        />
      )}
    </>
  );
};

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { TAB_TITLES as SECURITY_TAB_TITLES } from "components/Security";
import { TABS as SECURITY_TABS } from "components/Security/types";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { Table } from "components/common/Table";
import {
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as networksActions from "modules/networks/actions";
import {
  firewallSelector,
  firewallsSelector,
  isFirewallDeletingSelector,
  isFirewallRuleCreatingSelector,
  isFirewallRuleDeletingSelector,
  isFirewallUpdatingSelector,
  tableFirewallRulesSelector
} from "modules/networks/selectors";
import { TableFirewallRule } from "modules/networks/types";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { isNumber } from "typeGuards/isNumber";
import { isSelectOption } from "typeGuards/isSelectOption";
import { capitalize } from "utils/capitalize";
import { formatProtocol } from "utils/formatProtocol";
import { generateSearchString } from "utils/generateSearchString";
import { getParentPath } from "utils/getParentPath";
import { getSelectOption } from "utils/getSelectOption";
import { validateName } from "utils/validateName";
import {
  NumberSchema,
  Schema,
  StringSchema,
  mixed,
  number,
  ref,
  string
} from "yup";
import {
  ENTITY_NAME_LENGTH,
  MAX_ICMP_CODE,
  MAX_ICMP_TYPE,
  MAX_PORT,
  MIN_ICMP_CODE,
  MIN_ICMP_TYPE,
  MIN_PORT,
  REGEX,
  ROUTES
} from "../../constants";
import { PROTOCOLS } from "../../types";
import * as s from "./styles";
import {
  DIALOG_TYPES,
  DIRECTIONS,
  PORT_OPTIONS_VALUES,
  REMOTE_OPTIONS_VALUES
} from "./types";

const POLL_ID_PREFIX = "FIREWALL";

const POLL_IDS = {
  firewall: "FIREWALL",
  firewallRules: "FIREWALL_RULES",
  firewalls: "FIREWALLS"
};

const firewallRuleTableColumns: TableColumn<TableFirewallRule>[] = [
  { key: "id", label: "ID" },
  { key: "direction", label: "Direction" },
  { key: "protocol", label: "Protocol" },
  { key: "ipVersion", label: "IP version" },
  { key: "portRange", label: "Port range" },
  { key: "ipRanges", label: "IP ranges" },
  { key: "remoteFirewall", label: "Remote firewall" },
  { key: "description", label: "Description" }
];

export const Firewall: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    regionId: string;
    projectId: string;
    firewallId: string;
  }>();
  const history = useNavigate();
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const firewall = useSelector(firewallSelector);
  const firewalls = useSelector(firewallsSelector);
  const firewallRules = useSelector(tableFirewallRulesSelector);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE_FIREWALL_RULE, isOpened: false });
  const isFirewallUpdating = useSelector(isFirewallUpdatingSelector);
  const isFirewallDeleting = useSelector(isFirewallDeletingSelector);
  const isFirewallOperationInProgress =
    isFirewallUpdating || isFirewallDeleting;
  const previousIsFirewallOperationInProgress = usePrevious(
    isFirewallOperationInProgress
  );
  const isFirewallRuleCreating = useSelector(isFirewallRuleCreatingSelector);
  const isFirewallRuleDeleting = useSelector(isFirewallRuleDeletingSelector);
  const isFirewallRuleOperationInProgress =
    isFirewallRuleCreating || isFirewallRuleDeleting;
  const previousIsFirewallRuleOperationInProgress = usePrevious(
    isFirewallRuleOperationInProgress
  );

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Security",
      url: generatePath(ROUTES.SECURITY, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Firewalls",
      url: `${generatePath(ROUTES.SECURITY, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })}?${generateSearchString({
        tab: SECURITY_TAB_TITLES[SECURITY_TABS.FIREWALLS]
      })}`
    },
    {
      text: firewall?.name || "",
      url: generatePath(ROUTES.FIREWALL, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        firewallId: matchParams.firewallId
      })
    }
  ];

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleCreateFirewallRuleButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_FIREWALL_RULE,
      isOpened: true
    });
  }, []);

  const handleDeleteFirewallRuleMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_FIREWALL_RULE,
      isOpened: true
    });
  }, []);

  const handleEditFirewallButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.EDIT_FIREWALL,
      isOpened: true
    });
  }, []);

  const handleDeleteFirewallButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.DELETE_FIREWALL,
      isOpened: true
    });
  }, []);

  const handleConfirmCreateFirewallRule = useCallback(
    (data: {
      firewall_id: string;
      description: string;
      direction: SelectOption;
      portOptions: SelectOption | null;
      port: string;
      port_range_max: string;
      port_range_min: string;
      type: number | null;
      code: number | null;
      protocol: SelectOption;
      remote_group_id: SelectOption | null;
      remote_ip_prefix: string;
      remote: SelectOption | null;
    }) => {
      if (firewall) {
        let portOptionsObj: {
          port_range_min?: number;
          port_range_max?: number;
        } = {};
        switch (data.portOptions?.value) {
          case PORT_OPTIONS_VALUES.PORT:
            portOptionsObj = {
              port_range_min: Number(data.port),
              port_range_max: Number(data.port)
            };
            break;
          case PORT_OPTIONS_VALUES.PORT_RANGE:
            portOptionsObj = {
              port_range_min: Number(data.port_range_min),
              port_range_max: Number(data.port_range_max)
            };
        }

        let icmpOptionsObj: {
          type?: number;
          code?: number;
        } = {};
        if ((data.protocol.value as PROTOCOLS) === PROTOCOLS.ICMP) {
          icmpOptionsObj = {
            ...(isNumber(data.type) ? { type: data.type } : {}),
            ...(isNumber(data.code) ? { code: data.code } : {})
          };
        }

        let remoteObj: {
          remote_ip_prefix?: string;
          remote_group_id?: string;
        } = {};

        switch (data.remote?.value) {
          case REMOTE_OPTIONS_VALUES.CIDR:
            remoteObj = {
              remote_ip_prefix: data.remote_ip_prefix
            };
            break;
          case REMOTE_OPTIONS_VALUES.FIREWALL:
            remoteObj = {
              remote_group_id: data.remote_group_id?.value
            };
        }

        dispatch(
          networksActions.createFirewallRule.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            data: {
              description: data.description.trim(),
              firewall_id: firewall.id,
              direction: data.direction.value,
              protocol: data.protocol.value as PROTOCOLS,
              ...portOptionsObj,
              ...icmpOptionsObj,
              ...remoteObj
            }
          })
        );
      }
      handleCloseDialog();
    },
    [
      dispatch,
      firewall,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId
    ]
  );

  const handleConfirmDeleteFirewallRule = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        networksActions.deleteFirewallRule.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    dispatch,
    selectedItemId,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId
  ]);

  const handleConfirmEditFirewall = useCallback(
    (data: { name: string; description: string }) => {
      dispatch(
        networksActions.updateFirewall.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: matchParams.firewallId!,
          data: {
            ...data,
            description: data.description.trim()
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      matchParams.projectId,
      handleCloseDialog,
      matchParams.regionId,
      matchParams.firewallId
    ]
  );

  const handleConfirmDeleteFirewall = useCallback(() => {
    dispatch(
      networksActions.deleteFirewall.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        id: matchParams.firewallId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.projectId,
    handleCloseDialog,
    matchParams.regionId,
    matchParams.firewallId
  ]);

  const firewallRuleTableActions: TableRowActionsMenuItem<TableFirewallRule>[] =
    [
      {
        label: "Delete",
        handler: handleDeleteFirewallRuleMenuItemClick
      }
    ];

  useMount(() => {
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.firewall}`,
        action: networksActions.getFirewall.started({
          projectId: matchParams.projectId!,
          regionId: matchParams.regionId!,
          id: matchParams.firewallId!
        })
      })
    );
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.firewallRules}`,
        action: networksActions.getFirewallRules.started({
          projectId: matchParams.projectId!,
          regionId: matchParams.regionId!,
          id: matchParams.firewallId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.firewalls}`,
        action: networksActions.getFirewalls.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(enterprisesActions.clear());
    dispatch(projectsActions.clear());
    dispatch(networksActions.clear());
  });

  useEffect(() => {
    if (
      previousIsFirewallRuleOperationInProgress &&
      !isFirewallRuleOperationInProgress
    ) {
      dispatch(
        networksActions.getFirewallRules.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!,
          id: matchParams.firewallId!
        })
      );
    }
  }, [
    previousIsFirewallRuleOperationInProgress,
    isFirewallRuleOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.firewallId
  ]);

  useEffect(() => {
    if (
      previousIsFirewallOperationInProgress &&
      !isFirewallOperationInProgress
    ) {
      if (!firewall) {
        history(getParentPath(location.pathname, 2));
      } else {
        dispatch(
          networksActions.getFirewall.started({
            regionId: matchParams.regionId!,
            projectId: matchParams.projectId!,
            id: matchParams.firewallId!
          })
        );
      }
    }
  }, [
    firewall,
    history,
    previousIsFirewallOperationInProgress,
    isFirewallOperationInProgress,
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.firewallId
  ]);

  const generateTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.FIREWALL_RULE, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        firewallId: matchParams.firewallId,
        firewallRuleId: id
      }),
    [
      matchParams.projectId,
      matchParams.organizationId,
      matchParams.firewallId,
      matchParams.regionId
    ]
  );

  const previousSelectedItemId = usePrevious(selectedItemId);
  const deletingItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const deletingFirewallRuleId = firewallRules?.find(
    (firewallRule) => firewallRule.id === deletingItemId
  )?.id;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE_FIREWALL_RULE]: {
      onConfirm: handleConfirmCreateFirewallRule,
      title: "Create firewall rule",
      confirmButtonLabel: "Create",
      yupNoSortEdges: [["port_range_min", "port_range_max"]],
      fields: [
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          rules: string()
        },
        {
          name: "direction",
          type: FIELD_TYPES.SELECT,
          label: "Direction",
          options: Object.values(DIRECTIONS).map((value) => ({
            label: capitalize(value),
            value
          })),
          rules: selectOptionSchema
        },
        {
          name: "protocol",
          type: FIELD_TYPES.SELECT,
          label: "Protocol",
          options: Object.values(PROTOCOLS)
            .filter((protocol) => protocol !== PROTOCOLS.ANY)
            .map((value) => ({
              label: formatProtocol(value),
              value
            })),
          rules: selectOptionSchema
        },
        {
          name: "portOptions",
          type: FIELD_TYPES.SELECT,
          label: "Port options",
          options: [
            { label: "Port", value: PORT_OPTIONS_VALUES.PORT },
            { label: "Port range", value: PORT_OPTIONS_VALUES.PORT_RANGE },
            { label: "All ports", value: PORT_OPTIONS_VALUES.ALL_PORTS }
          ],
          rules: selectOptionSchema.when(
            "protocol",
            // (value: SelectOption | null, schema: SchemaOf<SelectOption>) =>
            (value: SelectOption[], schema: Schema<SelectOption>) =>
              [PROTOCOLS.TCP, PROTOCOLS.UDP].includes(
                value[0]?.value as PROTOCOLS
              )
                ? schema
                : mixed().notRequired()
          ),
          isHidden: (fieldValues) =>
            !fieldValues.protocol ||
            (isSelectOption(fieldValues.protocol) &&
              ![PROTOCOLS.TCP, PROTOCOLS.UDP].includes(
                fieldValues.protocol.value as PROTOCOLS
              ))
        },
        {
          name: "port",
          type: FIELD_TYPES.NUMBER,
          min: MIN_PORT,
          max: MAX_PORT,
          rules: number()
            .integer()
            .nullable()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(
              MIN_PORT,
              `Port must be greater than or equals to ${MIN_PORT}.`
            )
            .max(MAX_PORT, `Port must be less than ${MAX_PORT}.`),
          isHidden: (fieldValues) =>
            !fieldValues.portOptions ||
            (isSelectOption(fieldValues.portOptions) &&
              (fieldValues.portOptions.value as PORT_OPTIONS_VALUES) !==
                PORT_OPTIONS_VALUES.PORT),
          label: "Port"
        },
        {
          name: "port_range_min",
          type: FIELD_TYPES.NUMBER,
          min: MIN_PORT,
          max: MAX_PORT,
          rules: number()
            .integer()
            .nullable()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            .min(
              MIN_PORT,
              `Port must be greater than or equals to ${MIN_PORT}.`
            )
            // .when("port_range_max", (value: number[], schema: NumberSchema) =>
            //   Number.isInteger(value[0])
            //     ? schema.max(
            //         ref("port_range_max"),
            //         `Port must be less than ${value[0]}.`
            //       )
            //     : schema.max(MAX_PORT, `Port must be less than ${MAX_PORT}.`)
            // ),
            .when(["port_range_max"], {
              is: (portRangeMax) =>
                portRangeMax !== undefined && portRangeMax !== null,
              then: (schema) =>
                schema.max(
                  ref("port_range_max"),
                  ({ max }) =>
                    `Port must be less than or equals to the end port (${max}).`
                ),
              otherwise: (schema) =>
                schema.max(MAX_PORT, `Port must be less than ${MAX_PORT}`)
            }),
          isHidden: (fieldValues) =>
            !fieldValues.portOptions ||
            (isSelectOption(fieldValues.portOptions) &&
              (fieldValues.portOptions.value as PORT_OPTIONS_VALUES) !==
                PORT_OPTIONS_VALUES.PORT_RANGE),
          label: "Start port"
        },
        {
          name: "port_range_max",
          type: FIELD_TYPES.NUMBER,
          min: MIN_PORT,
          max: MAX_PORT,
          rules: number()
            .integer()
            .nullable()
            .transform(
              (value: number | null, originalValue: string | number) => {
                if (
                  typeof originalValue === "string" &&
                  originalValue.trim() === ""
                ) {
                  return null;
                }
                return typeof value === "number" ? value : null;
              }
            )
            // .when("port_range_min", (value: number[], schema: NumberSchema) =>
            //   Number.isInteger(value[0]) && Number(value[0]) <= MAX_PORT
            //     ? schema.min(
            //         ref("port_range_min"),
            //         `Port must be greater than or equals to ${value[0]}.`
            //       )
            //     : schema.min(
            //         MIN_PORT,
            //         `Port must be greater than or equals to ${MIN_PORT}.`
            //       )
            // )
            .max(MAX_PORT, `Port must be less than ${MAX_PORT}.`)
            .when(["port_range_min"], {
              is: (portRangeMin) =>
                portRangeMin !== undefined && portRangeMin !== null,
              then: (schema) =>
                schema.min(
                  ref("port_range_min"),
                  ({ min }) =>
                    `Port must be greater than or equals to the start port (${min}).`
                ),
              otherwise: (schema) =>
                schema.min(MIN_PORT, `Port must be greater than ${MIN_PORT}`)
            }),
          label: "End port",
          isHidden: (fieldValues) =>
            !fieldValues.portOptions ||
            (isSelectOption(fieldValues.portOptions) &&
              (fieldValues.portOptions.value as PORT_OPTIONS_VALUES) !==
                PORT_OPTIONS_VALUES.PORT_RANGE)
        },
        {
          name: "type",
          type: FIELD_TYPES.NUMBER,
          min: MIN_ICMP_TYPE,
          max: MAX_ICMP_TYPE,
          rules: number()
            .integer()
            .min(
              MIN_ICMP_TYPE,
              `ICMP type must be greater than or equals to ${MIN_ICMP_TYPE}.`
            )
            .max(MAX_ICMP_TYPE, `ICMP type must be less than ${MAX_ICMP_TYPE}.`)
            .nullable()
            .transform((value, originalValue) =>
              String(originalValue).trim() === "" ? null : Number(value)
            ),
          isHidden: (fieldValues) =>
            !fieldValues.protocol ||
            (isSelectOption(fieldValues.protocol) &&
              (fieldValues.protocol.value as PROTOCOLS) !== PROTOCOLS.ICMP),
          label: "Type"
        },
        {
          name: "code",
          type: FIELD_TYPES.NUMBER,
          min: MIN_ICMP_CODE,
          max: MAX_ICMP_CODE,
          rules: number()
            .integer()
            .min(
              MIN_ICMP_CODE,
              `ICMP code must be greater than or equals to ${MIN_ICMP_CODE}.`
            )
            .max(MAX_ICMP_CODE, `ICMP code must be less than ${MAX_ICMP_CODE}.`)
            .nullable()
            .transform((value, originalValue) =>
              String(originalValue).trim() === "" ? null : Number(value)
            ),
          isHidden: (fieldValues) =>
            !fieldValues.protocol ||
            (isSelectOption(fieldValues.protocol) &&
              (fieldValues.protocol.value as PROTOCOLS) !== PROTOCOLS.ICMP),
          label: "Code"
        },
        {
          name: "remote",
          type: FIELD_TYPES.SELECT,
          label: "Remote",
          options: [
            { label: "CIDR", value: REMOTE_OPTIONS_VALUES.CIDR },
            { label: "Firewall", value: REMOTE_OPTIONS_VALUES.FIREWALL }
          ],
          rules: selectOptionSchema.nullable()
        },
        {
          name: "remote_group_id",
          type: FIELD_TYPES.SELECT,
          label: "Remote firewall",
          options: firewalls?.map((firewall) =>
            getSelectOption(firewall, "name", "id")
          ),
          rules: selectOptionSchema.when(
            "remote",
            // (value: SelectOption | null, schema: SchemaOf<SelectOption>) =>
            (value: SelectOption[], schema: Schema<SelectOption>) =>
              (value[0]?.value as REMOTE_OPTIONS_VALUES) ===
              REMOTE_OPTIONS_VALUES.FIREWALL
                ? schema
                : mixed().notRequired()
          ),
          isHidden: (fieldValues) =>
            !fieldValues.remote ||
            (isSelectOption(fieldValues.remote) &&
              (fieldValues.remote.value as REMOTE_OPTIONS_VALUES) !==
                REMOTE_OPTIONS_VALUES.FIREWALL)
        },
        {
          name: "remote_ip_prefix",
          type: FIELD_TYPES.TEXT,
          label: "Remote IP range",
          rules: string().when(
            "remote",
            // (value: SelectOption | null, schema: StringSchema) =>
            (value: SelectOption[], schema: StringSchema) =>
              (value[0]?.value as REMOTE_OPTIONS_VALUES) ===
              REMOTE_OPTIONS_VALUES.CIDR
                ? schema
                    .required()
                    .matches(
                      REGEX.IP_ADDRESS_WITH_NET_MASK,
                      'Remote IP range must have the valid format (e.g., "192.168.1.101/24")'
                    )
                : schema
          ),
          isHidden: (fieldValues) =>
            !fieldValues.remote ||
            (isSelectOption(fieldValues.remote) &&
              (fieldValues.remote.value as REMOTE_OPTIONS_VALUES) !==
                REMOTE_OPTIONS_VALUES.CIDR)
        }
      ]
    },
    [DIALOG_TYPES.DELETE_FIREWALL_RULE]: {
      onConfirm: handleConfirmDeleteFirewallRule,
      title: `Are you sure you want to delete "${
        deletingFirewallRuleId ?? "selected"
      }" firewall rule?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.EDIT_FIREWALL]: {
      onConfirm: handleConfirmEditFirewall,
      title: "Edit firewall",
      confirmButtonLabel: "Save",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          defaultValue: firewall?.name || "",
          rules: string()
            .required()
            .test({
              name: "validateName",
              test: validateName(ENTITY_NAME_LENGTH)
            })
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue: firewall?.description || "",
          rules: string()
        }
      ]
    },
    [DIALOG_TYPES.DELETE_FIREWALL]: {
      onConfirm: handleConfirmDeleteFirewall,
      title: `Are you sure you want to delete "${
        firewall?.name ?? "selected"
      }" firewall?`,
      confirmButtonLabel: "Delete"
    }
  };

  const title = firewall?.name;

  return (
    <>
      <Head title={title} />
      {firewall ? (
        <>
          {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          <s.SummaryContainer>
            <s.SummaryColumn>
              <s.Title variant={"h4"} component={"h2"}>
                {title}
              </s.Title>
              <s.SummaryRow>
                <s.DetailsTitle>ID: </s.DetailsTitle>
                <s.DetailsInfoColored>{firewall.id}</s.DetailsInfoColored>
              </s.SummaryRow>
            </s.SummaryColumn>
            <s.ActionsContainer>
              <Tooltip title={"Edit"} arrow>
                <span>
                  <IconButton
                    onClick={handleEditFirewallButtonClick}
                    color={"inherit"}
                    // title={"Edit"}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={"Delete"} arrow>
                <span>
                  <IconButton
                    onClick={handleDeleteFirewallButtonClick}
                    color={"inherit"}
                    // title={"Delete"}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </s.ActionsContainer>
          </s.SummaryContainer>
          <Table
            isSearchEnabled={true}
            isSortingEnabled={true}
            rows={firewallRules || []}
            columns={firewallRuleTableColumns}
            actions={firewallRuleTableActions}
            itemLink={{
              column: "id",
              getURL: generateTableItemURL
            }}
            isLoading={!firewallRules}
            toolbarItems={
              <Button
                onClick={handleCreateFirewallRuleButtonClick}
                variant={"contained"}
              >
                Create firewall rule
              </Button>
            }
          />
        </>
      ) : (
        <Loader text={"Loading data..."} />
      )}
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        fields={dialogProps[dialog.type].fields}
        yupNoSortEdges={dialogProps[dialog.type].yupNoSortEdges}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
      />
    </>
  );
};

import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { Switch, Tooltip } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  FC,
  Fragment,
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { Controller, FieldError, useForm } from "react-hook-form";
import Repeatable from "react-repeatable";
import { isNumber } from "typeGuards/isNumber";
import { isSelectOption } from "typeGuards/isSelectOption";
import { isUndefined } from "typeGuards/isUndefined";
import { Schema, object, string } from "yup";
import { appConfig } from "../../../appConfig";
import { DATE_FORMATS, ERROR_MESSAGES } from "../../../constants";
import { Loader } from "../Loader";
import * as s from "./styles";
import {
  FIELD_TYPES,
  FormDialogField,
  FormDialogProps,
  SelectOption,
  SelectOptionWIthIcon,
  FormData
} from "./types";

export const selectOptionSchema: Schema<SelectOption> = object({
  label: string().required(),
  value: string().required()
});

const filter = createFilterOptions<SelectOption>();

const CREATE_OPTION_VALUE = "__CREATE_OPTION";

const handleArrowUpButtonClick = (
  fieldValues: Record<string, unknown>,
  field: FormDialogField,
  value: unknown,
  onChange: (value: number) => void
) => {
  const min =
    typeof field.min === "function" ? field.min(fieldValues) : field.min;
  const max =
    typeof field.max === "function" ? field.max(fieldValues) : field.max;
  const step = isNumber(field.step) && field.step > 0 ? field.step : 1;

  if (value === "") {
    onChange(isNumber(min) ? min : 0 + step);
  } else if (Number(value) < (isNumber(max) ? max : Number.POSITIVE_INFINITY)) {
    if (isNumber(max) && max - Number(value) < step) {
      onChange(max);
    } else {
      onChange(Math.floor(Number(value) / step) * step + step);
    }
  }
};

const handleArrowDownButtonClick = (
  fieldValues: Record<string, unknown>,
  field: FormDialogField,
  value: unknown,
  onChange: (value: number) => void
) => {
  const min =
    typeof field.min === "function" ? field.min(fieldValues) : field.min;
  const step = isNumber(field.step) && field.step > 0 ? field.step : 1;

  if (value === "") {
    onChange(isNumber(min) ? min : 0 - step);
  } else if (Number(value) > (isNumber(min) ? min : Number.NEGATIVE_INFINITY)) {
    if (isNumber(min) && Number(value) - min < step) {
      onChange(min);
    } else {
      onChange(Math.ceil(Number(value) / step) * step - step);
    }
  }
};

export const FormDialog: FC<FormDialogProps> = ({
  title,
  fields,
  yupNoSortEdges,
  onConfirm,
  onCancel,
  isOpened,
  isLoading,
  cancelButtonLabel = "Cancel",
  confirmButtonLabel = "OK",
  isLocked
}: FormDialogProps) => {
  const [areDividersVisible, setAreDividersVisible] = useState(false);
  const [formDialogContentNode, setFormDialogContentNode] =
    useState<HTMLDivElement | null>(null);
  const validationSchema = useMemo(
    () =>
      object().shape(
        fields?.reduce(
          (schemaObj, field) => ({
            ...schemaObj,
            [field.name]: field.rules
          }),
          {}
        ) || {},
        yupNoSortEdges
      ),
    [fields, yupNoSortEdges]
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    resetField,
    setValue
  } = useForm<FormData>({
    mode: "onChange",
    resolver: yupResolver(validationSchema),
    shouldUnregister: true
  });

  const fieldValues = watch();

  const handleCancelButtonClick = useCallback(() => {
    onCancel();
  }, [onCancel]);

  // const onSubmit = useCallback(
  //   (data) => {
  //     onConfirm(data);
  //   },
  //   [onConfirm]
  // );

  const onSubmit = useCallback(
    (data) => {
      // reset hidden fields
      fields?.forEach((field) => {
        if (typeof field.isHidden === "function") {
          if (field.isHidden(fieldValues)) {
            resetField(field.name);
          }
        } else if (field.isHidden) {
          resetField(field.name);
        }
      });
      onConfirm(data);
    },
    [fields, onConfirm, fieldValues, resetField]
  );

  const handleClose = useCallback(
    (e, reason) => {
      if (reason === "escapeKeyDown" && (!fields || fields.length === 0)) {
        onCancel();
      }
    },
    [fields, onCancel]
  );

  const getErrorMessage = useCallback(
    (field: FormDialogField) => {
      let fieldError = errors[field.name] as FieldError | FieldError[];
      if (Array.isArray(fieldError)) {
        // Filter non-empty elements
        fieldError = fieldError.filter((x) => x)[0];
      }
      if (!fieldError) {
        return undefined;
      }

      switch (fieldError.type) {
        case "required":
        case "nullable":
        case "typeError":
          return ERROR_MESSAGES.REQUIRED;
        default:
          return fieldError.message;
      }
    },
    [errors]
  );

  const isUsernameFieldExists = useMemo(
    () => Boolean(fields?.some((field) => field.autocomplete === "username")),
    [fields]
  );

  const passwordFields = useMemo(
    () =>
      fields?.reduce(
        (acc, cur, i) =>
          cur.type === FIELD_TYPES.PASSWORD ? [...acc, i] : acc,
        [] as number[]
      ) || [],
    [fields]
  );

  useEffect(() => {
    if (isOpened) {
      reset();
    }
  }, [isOpened, reset]);

  useEffect(() => {
    const handleResize = () => {
      if (formDialogContentNode) {
        const isScrollable =
          formDialogContentNode.scrollHeight >
          formDialogContentNode.clientHeight;
        setAreDividersVisible(isScrollable);
      } else {
        setAreDividersVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [formDialogContentNode]);

  const handleFormDialogContentRef = useCallback((node: HTMLDivElement) => {
    setFormDialogContentNode(node);
  }, []);

  const FORM_ID = `dialog-${title.replace(/\s/g, "-")}`;

  // const handleDatePickerChange = (fieldName, date) => {
  //   setValue(fieldName, date, { shouldValidate: true });
  // };

  return (
    <s.Dialog
      open={isOpened}
      onClose={handleClose}
      scroll={"paper"}
      data-testid={"dialog"}
      closeAfterTransition={false}
    >
      <DialogTitle
        data-testid={"dialog-title"}
        style={{ whiteSpace: "pre-line" }}
      >
        {title}
      </DialogTitle>
      {appConfig.areFormDevToolsEnabled && <DevTool control={control} />}
      <s.FormDialogContent
        ref={handleFormDialogContentRef}
        dividers={areDividersVisible}
      >
        <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
          {isLoading ? (
            <s.LoaderContainer>
              <Loader text={"Loading data..."} />
            </s.LoaderContainer>
          ) : (
            <>
              {fields?.map((field, i) => {
                // hiding the form field with CSS to fix Warning: Cannot update a component (DevTool) while rendering a different component.
                // const isHidden =
                //   typeof field.isHidden === "function"
                //     ? field.isHidden(fieldValues)
                //     : field.isHidden;

                // return (
                //   <div
                //     key={field.name}
                //     style={{ display: isHidden ? "none" : "inline" }}
                //   >
                //     {(() => {
                if (
                  isUndefined(field.isHidden) ||
                  (typeof field.isHidden === "function" &&
                    Object.keys(fieldValues).length > 0 &&
                    !field.isHidden(fieldValues))
                ) {
                  switch (field.type) {
                    case FIELD_TYPES.CHECKBOX:
                      return (
                        <FormControlLabel
                          key={field.name}
                          control={
                            <Controller
                              render={({ field: { value, onChange } }) => (
                                <Checkbox
                                  sx={{
                                    margin: field.margin
                                      ? field.margin
                                      : undefined
                                  }}
                                  checked={value}
                                  onChange={(e) => {
                                    const newValue = e.target.checked;
                                    onChange(newValue);
                                    if (newValue && field.onCreate) {
                                      field.onCreate();
                                    }
                                  }}
                                  // onChange={(e) => onChange(e.target.checked)}
                                  autoFocus={i === 0}
                                />
                              )}
                              name={field.name}
                              control={control}
                              defaultValue={
                                typeof field.defaultValue === "function"
                                  ? field.defaultValue(fieldValues)
                                  : field.defaultValue || false
                              }
                            />
                          }
                          label={
                            typeof field.label === "function"
                              ? field.label(fieldValues)
                              : field.label
                          }
                          sx={field.fullWidth ? { width: "100%" } : null}
                        />
                      );

                    case FIELD_TYPES.TOGGLE:
                      return (
                        <FormControlLabel
                          key={field.name}
                          control={
                            <Controller
                              render={({ field: { value, onChange } }) => (
                                <Switch
                                  sx={{ margin: "8px 0" }}
                                  checked={value}
                                  onChange={(e) => {
                                    const newValue = e.target.checked;
                                    onChange(newValue);
                                    if (newValue && field.onCreate) {
                                      field.onCreate();
                                    }
                                  }}
                                  // onChange={(e) => onChange(e.target.checked)}
                                  autoFocus={i === 0}
                                />
                              )}
                              name={field.name}
                              control={control}
                              defaultValue={
                                typeof field.defaultValue === "function"
                                  ? field.defaultValue(fieldValues)
                                  : field.defaultValue || false
                              }
                            />
                          }
                          label={
                            typeof field.label === "function"
                              ? field.label(fieldValues)
                              : field.label
                          }
                          // labelPlacement={"start"}
                          sx={field.fullWidth ? { width: "100%" } : null}
                        />
                      );

                    case FIELD_TYPES.TOGGLE_BUTTON:
                      if (field.options) {
                        return (
                          <Controller
                            key={field.name}
                            name={field.name}
                            control={control}
                            defaultValue={field.defaultValue}
                            render={({ field: { value, onChange } }) => {
                              return (
                                <>
                                  <Tooltip
                                    title={
                                      field.helperText ? (
                                        <div style={{ whiteSpace: "pre-line" }}>
                                          {field.helperText}
                                        </div>
                                      ) : (
                                        "Choose option"
                                      )
                                    }
                                    arrow
                                    placement={"top"}
                                  >
                                    <s.ToggleButtonGroupStyled
                                      size={"small"}
                                      color="primary"
                                      value={value}
                                      exclusive
                                      fullWidth
                                      onChange={(value) => {
                                        if (field.dependent_fields) {
                                          field.dependent_fields.forEach(
                                            (dependent_field) => {
                                              setValue(dependent_field, null);
                                            }
                                          );
                                        }
                                        onChange(value);
                                      }}
                                    >
                                      {typeof field.options === "object"
                                        ? field.options?.map(
                                            (option: SelectOptionWIthIcon) => {
                                              return (
                                                <s.ToggleButtonStyled
                                                  key={option.label}
                                                  title={
                                                    field.name ===
                                                    "linux_distros"
                                                      ? option.label
                                                      : undefined
                                                  }
                                                  value={option.value}
                                                >
                                                  {typeof option.icon ===
                                                  "object"
                                                    ? option.icon
                                                    : option.label}
                                                </s.ToggleButtonStyled>
                                              );
                                            }
                                          )
                                        : null}
                                    </s.ToggleButtonGroupStyled>
                                  </Tooltip>
                                </>
                              );
                            }}
                          />
                        );
                      }
                      return null;

                    case FIELD_TYPES.MULTISELECT:
                    case FIELD_TYPES.SELECT:
                      if (field.options) {
                        return (
                          <Controller
                            key={field.name}
                            name={field.name}
                            control={control}
                            defaultValue={
                              typeof field.defaultValue === "function"
                                ? field.defaultValue(fieldValues)
                                : field.defaultValue ||
                                  (field.type === FIELD_TYPES.MULTISELECT
                                    ? []
                                    : null)
                            }
                            render={({ field: { value, onChange } }) => (
                              <Autocomplete
                                data-testid={"dialog-autocomplete"}
                                readOnly={field.readOnly}
                                ListboxProps={
                                  // TODO: fix types
                                  // More info: https://github.com/mui-org/material-ui/issues/27873#issuecomment-903522313
                                  {
                                    "data-testid": "dialog-autocomplete-listbox"
                                  } as HTMLAttributes<HTMLUListElement>
                                }
                                freeSolo={field.freeSolo}
                                onChange={(e, value) => {
                                  if (
                                    field.onCreate &&
                                    ((Array.isArray(value) &&
                                      value.some(
                                        (x) =>
                                          isSelectOption(x) &&
                                          x.value === CREATE_OPTION_VALUE
                                      )) ||
                                      (!Array.isArray(value) &&
                                        isSelectOption(value) &&
                                        value?.value === CREATE_OPTION_VALUE))
                                  ) {
                                    field.onCreate();
                                  } else {
                                    onChange(value);
                                  }
                                }}
                                filterOptions={(options, params) => {
                                  if (!field.freeSolo) {
                                    const filteredOptions = filter(
                                      options as SelectOption[],
                                      params
                                    );

                                    if (field.onCreate && field.createText) {
                                      filteredOptions.push({
                                        label: field.createText,
                                        value: CREATE_OPTION_VALUE
                                      });
                                    }

                                    return filteredOptions;
                                  }

                                  return options;
                                }}
                                value={value}
                                multiple={
                                  field.type === FIELD_TYPES.MULTISELECT
                                }
                                options={
                                  typeof field.options === "function"
                                    ? field.options(fieldValues) || []
                                    : field.options || []
                                }
                                getOptionLabel={(
                                  option: SelectOption | string
                                ) =>
                                  isSelectOption(option)
                                    ? option.label ||
                                      `no name (${option.value})`
                                    : option
                                }
                                isOptionEqualToValue={(
                                  option: string | SelectOption,
                                  value: string | SelectOption
                                ) =>
                                  isSelectOption(option) &&
                                  isSelectOption(value)
                                    ? option.value === value.value
                                    : option === value
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    autoFocus={i === 0}
                                    inputProps={{
                                      ...params.inputProps,
                                      title:
                                        typeof field.label === "function"
                                          ? field.label(fieldValues)
                                          : field.label
                                    }}
                                    label={
                                      typeof field.label === "function"
                                        ? field.label(fieldValues)
                                        : field.label
                                    }
                                    autoComplete={field.autocomplete || "off"}
                                    error={Boolean(errors[field.name])}
                                    helperText={
                                      errors[field.name]
                                        ? getErrorMessage(field)
                                        : field.helperText
                                    }
                                    fullWidth={true}
                                    margin={"normal"}
                                    data-testid={"dialog-autocomplete-input"}
                                    size={"small"}
                                    variant={
                                      field.variant ? field.variant : "standard"
                                    }
                                  />
                                )}
                              />
                            )}
                          />
                        );
                      }
                      return null;
                    case FIELD_TYPES.MULTISELECT_GROUPED:
                    case FIELD_TYPES.GROUPED_SELECT:
                      if (field.options) {
                        return (
                          <Controller
                            key={field.name}
                            name={field.name}
                            control={control}
                            defaultValue={
                              typeof field.defaultValue === "function"
                                ? field.defaultValue(fieldValues)
                                : field.defaultValue ||
                                  (field.type ===
                                  FIELD_TYPES.MULTISELECT_GROUPED
                                    ? []
                                    : null)
                            }
                            render={({ field: { value, onChange } }) => (
                              <Autocomplete
                                data-testid={"dialog-autocomplete"}
                                ListboxProps={
                                  // TODO: fix types
                                  // More info: https://github.com/mui-org/material-ui/issues/27873#issuecomment-903522313
                                  {
                                    "data-testid": "dialog-autocomplete-listbox"
                                  } as HTMLAttributes<HTMLUListElement>
                                }
                                freeSolo={field.freeSolo}
                                onChange={(e, value) => {
                                  if (
                                    field.onCreate &&
                                    ((Array.isArray(value) &&
                                      value.some(
                                        (x) =>
                                          isSelectOption(x) &&
                                          x.value === CREATE_OPTION_VALUE
                                      )) ||
                                      (!Array.isArray(value) &&
                                        isSelectOption(value) &&
                                        value?.value === CREATE_OPTION_VALUE))
                                  ) {
                                    field.onCreate();
                                  } else {
                                    onChange(value);
                                  }
                                }}
                                filterOptions={(options, params) => {
                                  if (!field.freeSolo) {
                                    const filteredOptions = filter(
                                      options as SelectOption[],
                                      params
                                    );
                                    if (field.onCreate && field.createText) {
                                      filteredOptions.push({
                                        label: field.createText,
                                        value: CREATE_OPTION_VALUE
                                      });
                                    }
                                    return filteredOptions;
                                  }

                                  return options;
                                }}
                                value={value}
                                multiple={
                                  field.type === FIELD_TYPES.MULTISELECT
                                }
                                options={
                                  typeof field.options === "function"
                                    ? field.options(fieldValues) || []
                                    : field.options || []
                                }
                                getOptionLabel={(
                                  option: SelectOption | string
                                ) =>
                                  isSelectOption(option)
                                    ? option.label ||
                                      `no name (${option.value})`
                                    : option
                                }
                                isOptionEqualToValue={(
                                  option: string | SelectOption,
                                  value: string | SelectOption
                                ) =>
                                  isSelectOption(option) &&
                                  isSelectOption(value)
                                    ? option.value === value.value
                                    : option === value
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    autoFocus={i === 0}
                                    inputProps={{
                                      ...params.inputProps,
                                      title:
                                        typeof field.label === "function"
                                          ? field.label(fieldValues)
                                          : field.label
                                    }}
                                    label={
                                      typeof field.label === "function"
                                        ? field.label(fieldValues)
                                        : field.label
                                    }
                                    autoComplete={field.autocomplete || "off"}
                                    error={Boolean(errors[field.name])}
                                    helperText={
                                      errors[field.name]
                                        ? getErrorMessage(field)
                                        : field.helperText
                                    }
                                    fullWidth={true}
                                    margin={"normal"}
                                    data-testid={"dialog-autocomplete-input"}
                                    size={"small"}
                                    variant={"standard"}
                                  />
                                )}
                                groupBy={(option) => {
                                  const value = isSelectOption(option)
                                    ? option.value
                                    : "";
                                  return (
                                    field.groupMap?.get(value.toLowerCase())
                                      ?.group || "Other"
                                  );
                                }}
                              />
                            )}
                          />
                        );
                      }
                      return null;
                    case FIELD_TYPES.NUMBER:
                    case FIELD_TYPES.PASSWORD:
                    case FIELD_TYPES.TEXT:
                    case FIELD_TYPES.MULTILINE_TEXT:
                      return (
                        <Fragment key={field.name}>
                          {/* 
                            Add hidden username field if it isn't present in form
                            to avoid Chrome warnings and to prevent Firefox to suggest autofill in wrong fields
                            More info: https://www.chromium.org/developers/design-documents/create-amazing-password-forms
                          */}
                          {passwordFields.length > 0 &&
                            i === passwordFields[0] &&
                            !isUsernameFieldExists && (
                              <s.HiddenInput
                                type={"text"}
                                autoComplete={"username"}
                              />
                            )}
                          <Controller
                            defaultValue={
                              typeof field.defaultValue === "function"
                                ? field.defaultValue(fieldValues)
                                : field.defaultValue || ""
                            }
                            control={control}
                            key={field.name}
                            name={field.name}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                data-testid={"dialog-textfield-" + field.name}
                                value={value}
                                onChange={onChange}
                                onKeyDown={(e) => {
                                  if (field.type === FIELD_TYPES.NUMBER) {
                                    if (e.key === "ArrowUp") {
                                      handleArrowUpButtonClick(
                                        fieldValues,
                                        field,
                                        value,
                                        onChange
                                      );
                                    }
                                    if (e.key === "ArrowDown") {
                                      handleArrowDownButtonClick(
                                        fieldValues,
                                        field,
                                        value,
                                        onChange
                                      );
                                    }
                                  }
                                }}
                                multiline={
                                  field.type === FIELD_TYPES.MULTILINE_TEXT
                                }
                                autoFocus={i === 0}
                                label={
                                  typeof field.label === "function"
                                    ? field.label(fieldValues)
                                    : field.label
                                }
                                fullWidth={true}
                                error={Boolean(errors[field.name])}
                                helperText={
                                  errors[field.name]
                                    ? getErrorMessage(field)
                                    : field.helperText
                                }
                                margin={"normal"}
                                type={
                                  field.type === FIELD_TYPES.MULTILINE_TEXT
                                    ? undefined
                                    : field.type === FIELD_TYPES.NUMBER
                                      ? FIELD_TYPES.TEXT
                                      : field.type
                                }
                                autoComplete={field.autocomplete || "off"}
                                inputProps={{
                                  "data-testid": "dialog-textfield-input",
                                  title:
                                    typeof field.label === "function"
                                      ? field.label(fieldValues)
                                      : field.label,
                                  readOnly: field.readOnly
                                }}
                                size={"small"}
                                variant={
                                  field.type === FIELD_TYPES.NUMBER
                                    ? "outlined"
                                    : field.variant || "standard"
                                }
                                InputProps={{
                                  endAdornment:
                                    field.suffix || FIELD_TYPES.NUMBER ? (
                                      <InputAdornment position={"end"}>
                                        {field.type === FIELD_TYPES.NUMBER && (
                                          <s.StepArrowsContainer>
                                            <Repeatable
                                              tag={s.StepButton}
                                              disabled={Number.isNaN(
                                                Number(value)
                                              )}
                                              onPress={() =>
                                                handleArrowUpButtonClick(
                                                  fieldValues,
                                                  field,
                                                  value,
                                                  onChange
                                                )
                                              }
                                              onHold={() =>
                                                handleArrowUpButtonClick(
                                                  fieldValues,
                                                  field,
                                                  value,
                                                  onChange
                                                )
                                              }
                                            >
                                              <s.ArrowUpIcon />
                                            </Repeatable>
                                            <Repeatable
                                              tag={s.StepButton}
                                              disabled={Number.isNaN(
                                                Number(value)
                                              )}
                                              onPress={() =>
                                                handleArrowDownButtonClick(
                                                  fieldValues,
                                                  field,
                                                  value,
                                                  onChange
                                                )
                                              }
                                              onHold={() =>
                                                handleArrowDownButtonClick(
                                                  fieldValues,
                                                  field,
                                                  value,
                                                  onChange
                                                )
                                              }
                                            >
                                              <s.ArrowDownIcon />
                                            </Repeatable>
                                          </s.StepArrowsContainer>
                                        )}
                                        {field.suffix && (
                                          <Typography color={"textSecondary"}>
                                            {field.suffix}
                                          </Typography>
                                        )}
                                      </InputAdornment>
                                    ) : undefined,
                                  sx: field.readOnly
                                    ? {
                                        // backgroundColor: "lightgray",
                                        color: "gray"
                                      }
                                    : undefined
                                }}
                              />
                            )}
                          />
                        </Fragment>
                      );

                    case FIELD_TYPES.FILE:
                      return (
                        <Fragment key={field.name}>
                          <Button variant="contained" component="label">
                            Upload File
                            <input
                              type="file"
                              name={field.name}
                              onChange={handleSubmit(onSubmit)}
                              style={{ display: "none" }}
                            />
                          </Button>
                          {watch(field.name) && (
                            <p>
                              Selected file: {(watch(field.name) as File).name}
                            </p>
                          )}
                        </Fragment>
                      );
                    case FIELD_TYPES.LABEL:
                      return (
                        <s.LabelFieldText key={field.name}>
                          {typeof field.label === "function" ? (
                            field.label(fieldValues)
                          ) : typeof field.link === "string" ? (
                            <s.StyledLink
                              href={field.link}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {field.link}
                            </s.StyledLink>
                          ) : (
                            field.label
                          )}
                        </s.LabelFieldText>
                      );

                    case FIELD_TYPES.DIVIDER:
                      return (
                        <s.DividerContainer key={field.name}>
                          {field.label ? (
                            // <Chip label={field.name} />
                            <s.DividerText>
                              {typeof field.label === "function"
                                ? field.label(fieldValues)
                                : field.label}
                            </s.DividerText>
                          ) : null}
                          <s.StyledDivider />
                        </s.DividerContainer>
                      );

                    case FIELD_TYPES.NOTES:
                      return (
                        <s.UserNotesContainer key={field.name}>
                          {typeof field.label === "function"
                            ? field.label(fieldValues)
                            : field.label}
                        </s.UserNotesContainer>
                      );
                    case FIELD_TYPES.STATIC_TIME_PICKER:
                      return (
                        <Controller
                          key={field.name}
                          name={field.name}
                          control={control}
                          defaultValue={field.defaultValue}
                          render={({ field: { value, onChange } }) => {
                            return (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <s.CenteredContainer>
                                  <s.StyledStaticTimePicker
                                    orientation="landscape"
                                    content={
                                      typeof field.label === "function"
                                        ? field.label(fieldValues)
                                        : field.label
                                    }
                                    value={value}
                                    // ampm={true}
                                    // ampmInClock={true}
                                    // ampm={false}
                                    // ampmInClock={false}
                                    minutesStep={5}
                                    onChange={onChange}
                                    slots={{
                                      actionBar: () => null
                                    }}
                                  />
                                </s.CenteredContainer>
                              </LocalizationProvider>
                            );
                          }}
                        />
                      );
                    case FIELD_TYPES.TIME_PICKER:
                      return (
                        <Controller
                          key={field.name}
                          name={field.name}
                          control={control}
                          defaultValue={field.defaultValue}
                          render={({ field: { value, onChange } }) => (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                label={field.labelPicker}
                                value={value}
                                onChange={onChange}
                                ampm={false}
                                ampmInClock={false}
                                slotProps={{
                                  textField: {
                                    InputProps: { size: "small" },
                                    style: { width: "100%" }
                                  }
                                }}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      );
                    case FIELD_TYPES.DATE_PICKER:
                      return (
                        <Controller
                          key={field.name}
                          name={field.name}
                          control={control}
                          defaultValue={field.defaultValue}
                          render={({ field: { value, onChange } }) => {
                            return (
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Tooltip
                                  title={
                                    field.helperText ? (
                                      <div style={{ whiteSpace: "pre-line" }}>
                                        {field.helperText}
                                      </div>
                                    ) : (
                                      ""
                                    )
                                  }
                                  arrow
                                  placement={"top"}
                                >
                                  <span>
                                    <DatePicker
                                      label={
                                        typeof field.label === "function"
                                          ? field.label(fieldValues)
                                          : field.label
                                      }
                                      onChange={onChange}
                                      // format="MMMM D, YYYY"
                                      format="D MMM YYYY"
                                      value={value}
                                      // format={DATE_FORMATS.ISO_DATE}
                                      minDate={field.minDate}
                                      maxDate={field.maxDate}
                                      disableFuture={field.disableFuture}
                                      disablePast={field.disablePast}
                                      sx={{
                                        margin: "12px 10px 10px 0",
                                        width: "47%"
                                      }}
                                      slotProps={{
                                        textField: {
                                          InputProps: {
                                            size: "small"
                                          }
                                        }
                                      }}
                                    />
                                  </span>
                                </Tooltip>
                              </LocalizationProvider>
                            );
                          }}
                        />
                      );
                  }
                  //     })()}
                  //   </div>
                  // );
                }
              })}
            </>
          )}
        </form>
      </s.FormDialogContent>
      <DialogActions>
        <Button
          disabled={isLocked}
          onClick={handleCancelButtonClick}
          color={"secondary"}
          data-testid={"dialog-secondary-button"}
        >
          {cancelButtonLabel}
        </Button>
        <Button
          color={"secondary"}
          autoFocus={!fields || fields.length === 0}
          type={"submit"}
          form={FORM_ID}
          data-testid={"dialog-submit-button"}
        >
          {confirmButtonLabel}
        </Button>
      </DialogActions>
    </s.Dialog>
  );
};

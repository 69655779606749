export enum RETENTION_TYPES {
  SNAPSHOTS = "Number of Snapshots to Keep",
  DAYS = "Number of days to retain Snapshots"
}
export const RETENTION_TYPES_LABELS = {
  [RETENTION_TYPES.SNAPSHOTS]: "number of backups to keep",
  [RETENTION_TYPES.DAYS]: "number of days to retain"
};

export enum WORKLOADS_TYPES {
  SERIAL = "f82ce76f-17fe-438b-aa37-7a023058e50d",
  PARALLEL = "2ddd528d-c9b4-4d7e-8722-cc395140255a"
}
export const WORKLOADS_TYPES_LABELS = {
  [WORKLOADS_TYPES.SERIAL]: "serial",
  [WORKLOADS_TYPES.PARALLEL]: "parallel"
};

export enum RESTORE_TYPES {
  SELECTIVE = "selective",
  INPLACE = "inplace"
}
export const RESTORE_TYPES_LABELS = {
  [RESTORE_TYPES.SELECTIVE]: "Selective",
  [RESTORE_TYPES.INPLACE]: "InPlace"
};

export enum FULL_BACKUP_INTERVALS_TYPES {
  ALWAYS = "ALWAYS",
  SNAPSHOTS = "CUSTOM",
  NEVER = "NEVER"
}
export const FULL_BACKUP_INTERVALS_TYPES_LABELS = {
  [FULL_BACKUP_INTERVALS_TYPES.ALWAYS]: "always full",
  [FULL_BACKUP_INTERVALS_TYPES.SNAPSHOTS]:
    "after number of incremental backups",
  [FULL_BACKUP_INTERVALS_TYPES.NEVER]: "never full"
};

export const FULL_BACKUP_INTERVALS_TYPES_VALUES = {
  [FULL_BACKUP_INTERVALS_TYPES.ALWAYS]: 0,
  [FULL_BACKUP_INTERVALS_TYPES.NEVER]: -1
};

//list Workload
// export type BackupsWorkloads = {
//   workloads: BackupsWorkload[];
// };
export type BackupsWorkloads = BackupsWorkload[];

export type BackupsWorkload = {
  project_id: string;
  user_id: string;
  id: string;
  name: string;
  snapshots_info: string;
  description: string;
  workload_type_id: string;
  status: string;
  created_at: string;
  updated_at?: string;
  scheduler_trust?: boolean | null | string;
  links?: {
    rel: string;
    href: string;
  }[];
};

export type TableBackupsWorkload = {
  id: string;
  name: string;
  workload_type: string;
  workload_type_id: string;
  status: string;
  description: string;
  created_at: string;
  updated_at?: string;
  snapshots_info: string;
};

export type GetBackupsWorkloadsParams = {
  orgId: string;
  projectId: string;
  region: string;
  pollingId?: string;
};

export type GetBackupsWorkloadsResponse = BackupsWorkloads;

//details Workload
export type BackupsWorkloadDetails = {
  project_id: string;
  user_id: string;
  id: string;
  name: string;
  description: string;
  status: string;
  error_msg: string | null;
  created_at: string;
  updated_at?: string;
  workload_type_id: string; // id
  availability_zone: string; //nova
  scheduler_trust: boolean | null | string;
  storage_usage: {
    usage: number;
    full: {
      snap_count: number;
      usage: number;
    };
    incremental: {
      snap_count: number;
      usage: number;
    };
  };
  instances: {
    name: string;
    id: string;
    metadata?: any;
  }[];
  metadata: {
    hostnames?: string;
    meta?: string;
    policy_id?: string;
    preferredgroup?: string;
    topology?: string;
    workload_approx_backup_size?: string;
  };
  jobschedule: {
    retention_policy_type: string;
    retention_policy_value: number;
    start_time: string;
    interval: string;
    enabled: boolean;
    timezone: string; // "UTC+2",
    start_date: string; //"10/27/2020",
    end_date: string;
    fullbackup_interval?: string; // string "-1" for never, "0", for always
    appliance_timezone?: string; // "UTC",
    global_jobscheduler?: boolean;
  };
  links?: {
    rel: string;
    href: string;
  }[];
};

export type TableBackupsWorkloadDetailsAssignedVMs = {
  name: string;
  id: string;
};

export type TableBackupsWorkloadDetails = {
  id: string;
  name: string;
  description: string;
  status: string;
  error_msg: string | null;
  created_at: string;
  updated_at?: string;
  workload_type_label: string;
  workload_type_id: string;
  scheduler_trust: boolean | null | string;
  availability_zone: string; //nova
  storage_usage_total: string;
  storage_usage_full: {
    snap_count: number;
    usage: string;
  };
  storage_usage_incremental: {
    snap_count: number;
    usage: string;
  };
  instances: {
    name: string;
    id: string;
  }[];
  retention_policy_type: string;
  retention_policy_type_label: string;
  retention_policy_value: number;
  retention_policy_value_str: string;
  end_date: string;
  start_time: string;
  start_time_formatted: string;
  start_time_formatted_string_24: string;
  schedule_interval: string;
  schedule_interval_label: string;
  schedule_enabled: boolean;
  timezone: string; // "UTC+2",
  start_date: string; //"10/27/2020",
  fullbackup_interval?: string; // string "-1",
};
export type GetBackupsWorkloadDetailsParams = {
  orgId: string;
  region: string;
  projectId: string;
  pollingId?: string;
  workloadId: string;
};

export type GetBackupsWorkloadDetailsResponse = {
  workload: BackupsWorkloadDetails;
};

//create Workload
export type CreateBackupsWorkloadParams = {
  region: string;
  orgId: string;
  projectId: string;
  data: {
    name: string;
    description?: string;
    workload_type_id: string;
    pause_at_snapshot?: boolean;
    instances: {
      "instance-id": string;
    }[];
    jobschedule: {
      retention_policy_type: string; // "<'Number of Snapshots to Keep'/'Number of days to retain Snapshots'>";
      retention_policy_value: number; // "<Integer>"; retention_snapshots or retention_days
      // retention_snapshots?: number;
      // retention_days?: number;
      timezone: string; // "<timezone>";
      start_date?: string; // "<Date format: MM/DD/YYYY>";
      end_date?: string; // "<Date format MM/DD/YYYY>";
      start_time?: string; // "<Time format: HH:MM AM/PM>";
      interval?: string; // "<Format: Integer hr";
      enabled: boolean; // "<True/False>";
      fullbackup_interval?: string;
    };
    metadata?: {
      policy_id?: string; // "<policy_id>";
    };
  };
};

export type CreateBackupsWorkloadResponse = { workload: BackupsWorkload };

//edit Workload
export type EditBackupsWorkloadParams = {
  region: string;
  orgId: string;
  projectId: string;
  id: string;
  data: {
    name?: string;
    description?: string;
    workload_type_id?: string;
    pause_at_snapshot?: boolean;
    instances?: {
      "instance-id": string;
    }[];
    jobschedule?: {
      retention_policy_type?: string; // "<'Number of Snapshots to Keep'/'Number of days to retain Snapshots'>";
      retention_policy_value?: number; // "<Integer>"; retention_snapshots or retention_days
      // retention_snapshots?: number;
      // retention_days?: number;
      timezone?: string; // "<timezone>";
      start_date?: string; // "<Date format: MM/DD/YYYY>";
      end_date?: string; // "<Date format MM/DD/YYYY>";
      start_time?: string; // "<Time format: HH:MM AM/PM>";
      interval?: string; // "<Format: Integer hr";
      enabled?: boolean; // "<True/False>";
      fullbackup_interval?: string;
    };

    metadata?: {
      policy_id?: string; // "<policy_id>";
    };
  };
};

export type EditBackupsWorkloadResponse = { workload: BackupsWorkload };

// delete workload
export type DeleteBackupsWorkloadParams = {
  orgId: string;
  region: string;
  projectId: string;
  id: string;
};

export type DeleteBackupsWorkloadResponse = { message: string };

// snapshots
export type BackupsWorkloadSnapshot = {
  id: string;
  created_at: string;
  status: string;
  snapshot_type: string;
  workload_id: string;
  name: string;
  description: string;
  host?: string;
};

export type GetBackupsWorkloadSnapshotsParams = {
  orgId: string;
  region: string;
  projectId: string;
  pollingId?: string;
  workloadId: string;
};

export type GetBackupsWorkloadSnapshotsResponse = BackupsWorkloadSnapshot[];

//create snapshot
export type CreateBackupsWorkloadSnapshotsParams = {
  region: string;
  orgId: string;
  projectId: string;
  workloadId: string;
  data: {
    name: string;
    description: string;
  };
};

export type CreateBackupsWorkloadSnapshotsResponse = {
  snapshot: BackupsWorkloadSnapshot;
};

// delete snapshot
export type DeleteBackupsWorkloadSnapshotsParams = {
  orgId: string;
  region: string;
  projectId: string;
  workloadId: string;
  id: string;
};

export type DeleteBackupsWorkloadSnapshotsResponse = { message: string };

// multi-delete snapshots
export type MultiDeleteBackupsWorkloadSnapshotsParams = {
  orgId: string;
  region: string;
  projectId: string;
  workloadId: string;
  snapshot_ids: string[];
};

export type MultiDeleteBackupsWorkloadSnapshotsResponse = { message: string };

// cancel snapshot
export type CancelBackupsWorkloadSnapshotsParams = {
  orgId: string;
  region: string;
  projectId: string;
  workloadId: string;
  id: string;
};

export type CancelBackupsWorkloadSnapshotsResponse = { message: string };

// details snapshot
export type BackupsWorkloadSnapshotDetails = {
  id: string;
  created_at: string;
  updated_at?: string;
  finished_at?: string;
  user_id: string;
  project_id: string;
  status: string;
  snapshot_type: string; // full
  workload_id: string;
  instances: {
    id: string;
    name: string;
    status: string;
    metadata?: any;
    flavor: {
      vcpus: string; //"1";
      ram: string; // "512";
      disk: string; //"1";
      ephemeral: string; //"0";
    };
    security_group: {
      name: string; //"default";
      security_group_type: string; //"neutron";
    }[];
    nics: {
      mac_address: string;
      ip_address: string;
      network: {
        id: string;
        name: string;
        cidr: string | null;
        network_type: string;
        subnet?: {
          id: string;
          name: string;
          cidr: string | null;
          ip_version: number;
          gateway_ip: string;
        };
      };
    }[];
    vdisks: {
      label?: string | null;
      resource_id: string;
      restore_size: number; // 1073741824;
      vm_id: string;
      volume_id: string;
      volume_name: string;
      volume_size: string;
      volume_type: string; //"iscsi";
      volume_mountpoint: string; //"/dev/vda";
      availability_zone: string; // "nova";
      metadata?: {
        readonly: string; //"False";
        attached_mode: string; //"rw";
      };
    }[];
  }[];
  name: string;
  description: string;
  host: string;
  size: number; //44171264;
  restore_size?: number;
  uploaded_size: number;
  progress_percent: number;
  progress_msg?: string;
  warning_msg?: string;
  error_msg?: string;
  time_taken: number;
  pinned?: boolean;
  metadata: {
    created_at: string;
    updated_at?: string;
    deleted_at?: string;
    deleted: boolean;
    version: string;
    id: string;
    snapshot_id: string;
    key: string;
    value: string; // "10.10.2.20:/upstream";
  }[];
  restores_info?: string; //"";
};

export type TableBackupsWorkloadSnapshotDetailsAssignedVMs = {
  name: string;
  id: string;
  status: string;
};

export type TableBackupsWorkloadSnapshotDetails = {
  id: string;
  name: string;
  description: string;
  created_at: string;
  updated_at?: string;
  finished_at?: string;
  time_taken: string;
  status: string;
  snapshot_type: string;
  workload_id: string;
  size: string; //44171264;
  restore_size: string;
  uploaded_size: string;
  progress_percent: string;
  progress_msg?: string;
  warning_msg?: string;
  error_msg?: string;
};

export type GetBackupsWorkloadSnapshotDetailsParams = {
  orgId: string;
  region: string;
  projectId: string;
  workloadId: string;
  snapshotId: string;
};

export type GetBackupsWorkloadSnapshotDetailsResponse = {
  snapshot: BackupsWorkloadSnapshotDetails;
};

// restores
export type Restore = {
  id: string;
  created_at: string;
  updated_at?: string;
  finished_at?: string;
  user_id: string;
  project_id: string;
  status: string;
  restore_type: string; // restore
  snapshot_id: string;
  links?: {
    rel: string;
    href: string;
  }[];
  name: string;
  description: string;
  host: string;
  size: number;
  uploaded_size: number;
  progress_percent: number;
  progress_msg: string;
  warning_msg: string;
  error_msg: string;
  time_taken: number;
  restore_options: {
    name: string;
    oneclickrestore: boolean;
    restore_type: string; // selective
    openstack: {
      instances: {
        name: string;
        id: string;
        availability_zone: string; //"nova";
      }[];
    };
    type: string; //"openstack";
    description: string;
  };
};
export type RestoreFromList = Restore & {
  metadata?: {
    created_at: string;
    updated_at?: string;
    deleted_at?: string;
    deleted: boolean;
    version: string;
    id: string;
    restore_id: string;
    key: string;
    value: string;
  }[];
};

export type TableRestore = {
  id: string;
  created_at: string;
  status: string;
  name: string;
  size: string;
  uploaded_size: string;
  progress_percent: string;
  warning_msg: string;
  error_msg: string;
  time_taken: string;
  restore_type: string;
  instances?: string;
};

// get restores
export type GetRestoresParams = {
  orgId: string;
  projectId: string;
  region: string;
  workloadId: string;
  snapshotId: string;
  pollingId?: string;
};

// export type GetRestoresResponse = { restores: RestoreFromList[] };
export type GetRestoresResponse = RestoreFromList[];

// create Selective restore
export type CreateSelectiveRestoreParams = {
  region: string;
  orgId: string;
  projectId: string;
  workloadId: string;
  snapshotId: string;
  data: {
    name: string;
    description: string;
    instances?: {
      id?: string;
      instance_name?: string;
      flavor_id?: string;
    }[];
    instance_ids?: string[];
    // networks_mapping?: {
    //   networks: {
    //     snapshot_network?: {
    //       subnet?: {
    //         id: string;
    //       };
    //       id?: string;
    //     };
    //     target_network?: {
    //       subnet?: {
    //         id: string;
    //       };
    //       id?: string;
    //       name?: string;
    //     };
    //   }[];
    // };
  };
};

export type CreateSelectiveRestoreResponse = {
  restore: Restore;
};

// create InPlace restore
export type CreateInPlaceRestoreParams = {
  region: string;
  orgId: string;
  projectId: string;
  workloadId: string;
  snapshotId: string;
  data: {
    restore: {
      name: string;
      description: string;
      options: {
        openstack: {
          instances: {
            restore_boot_disk: boolean;
            include: boolean;
            id: string;
            vdisks?: {
              restore_cinder_volume: boolean;
              id: string;
            }[];
          }[];
        };
      };
    };
  };
};

export type CreateInPlaceRestoreResponse = {
  restore: Restore;
};

// cancel restore
export type CancelRestoreParams = {
  orgId: string;
  region: string;
  projectId: string;
  workloadId: string;
  restoreId: string;
};

export type CancelRestoreResponse = { message: string };

// delete restore
export type DeleteRestoreParams = {
  orgId: string;
  region: string;
  projectId: string;
  workloadId: string;
  restoreId: string;
};

export type DeleteRestoreResponse = { message: string };
